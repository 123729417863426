<section class="col-8">
  <section class="content">
    <div
      class="section-container"
      (click)="setSectionToEdit(section, i)"
      *ngFor="let section of sections; let i = index"
    >
      <ng-template #sectionContainers></ng-template>
    </div>
  </section>
  <footer
    class="d-flex justify-content-center p-3"
    *ngIf="postFormGroup.valid && sections.length"
  >
    <p-button
      (click)="setPost()"
      styleClass="p-button-outlined p-button-rounded m-auto p-button-sm"
      class="m-auto"
      >{{ postId ? "Editar Post" : "Guardar Nuevo Post" }}</p-button
    >
  </footer>
</section>

<aside class="col border">
  <p-tabView>
    <p-tabPanel header="Secciones">
      <form class="m-auto d-flex flex-wrap" [formGroup]="formGroup">
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-select
            label="Componente"
            value-option="kind"
            [items]="availableComponents"
            formControlName="kind"
          >
          </acl-select>
        </div>
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-input
            label="Resumen"
            kind="textarea"
            [rows]="10"
            formControlName="data"
          ></acl-input>
        </div>
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-switch
            background-color="var(--primary-color)"
            label="JSON"
            formControlName="json"
          ></acl-switch>
        </div>

        <div class="col-12 py-3 d-flex">
          <p-button
            (click)="addSection()"
            styleClass="p-button-outlined p-button-rounded m-auto p-button-sm"
            class="m-auto"
            >{{ editing ? "Editar" : "Agregar" }}</p-button
          >
        </div>
      </form>
    </p-tabPanel>
    <p-tabPanel header="General">
      <form class="m-auto d-flex flex-wrap" [formGroup]="postFormGroup">
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-input label="Título" formControlName="title"> </acl-input>
        </div>
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-input label="Subtítulo" formControlName="subtitle"> </acl-input>
        </div>
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-select
            label="Tipo de Post"
            formControlName="category"
            [items]="categoryOptions"
          >
          </acl-select>
        </div>
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-input
            label="Resumen"
            kind="textarea"
            [rows]="10"
            formControlName="summary"
          ></acl-input>
        </div>
        <div class="d-flex flex-column my-2 gap-2 col-12 px-2">
          <acl-file [available-formats]="['jpg', 'jpeg']" formControlName="img">
          </acl-file>
        </div>
      </form>
    </p-tabPanel>
  </p-tabView>
</aside>
