import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorFromValidatorType } from '../../../core/validators/validators-mapper';

export type ErrorMapper = {
  [key in ErrorFromValidatorType]: (data: any) => string;
};
@Component({
  selector: 'tyn-errors-modal',
  templateUrl: './errors-modal.component.html',
  styleUrls: ['./errors-modal.component.scss'],
})
export class ErrorsModalComponent implements OnInit {
  goTo(element: HTMLElement | undefined) {
    if (element) {
      element.focus();
      this.dialogRef.close();
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.style.border = '2px dashed var(--warn-color)';
        element.style.borderRadius = '1rem';
        setTimeout(() => {
          element.style.border = 'none';
          element.style.borderRadius = 'initial';
        }, 3000);
      }, 100);
    }
  }
  @Input() errors: {
    property: string;
    errors: { [keyError in ErrorFromValidatorType]: any }[];
    display: string;
    target: HTMLElement;
  }[] = [];

  normalizedErrors: {
    display: string;
    errors: string[];
    target?: HTMLElement;
  }[] = [];

  errorsMapper: ErrorMapper;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ErrorsModalComponent>
  ) {
    this.errorsMapper = {
      required: () => 'Este campo es requerido.',
      email: () => 'El correo debe tener un formato válido.',
      haveToBeDefined: () => 'El campo no puede ser nulo o indefinido',
      max: (data) => `El valor es mayor a ${data['max']}`,
      maxlength: (data) =>
        `No debe superar los ${data['requiredLength']} dígitos en el campo, actualmente es de ${data['actualLength']}`,
      min: (data) => `El valor es menor a ${data['min']}`,
      minlength: (data) =>
        `La cantidad de dígitos debe ser al menos de ${data['requiredLength']}, actualmente es de ${data['actualLength']}.`,
      pattern: (data) =>
        `No cumple con el patrón: '${data['requiredPattern']}'`,
      requiredTrue: () => 'Debe ser verdadera la expresión y es falsa',
    };
  }

  ngOnInit(): void {
    if (this.data) {
      this.errors = this.data.errors;
    }
    this.normalizeErrors();
  }

  normalizeErrors() {
    console.log('Errores');
    console.log(this.errors);

    this.normalizedErrors = this.errors.map((errorObj) => {
      const texts: string[] = [];
      const { target, errors, display } = errorObj;
      for (const errorCode in errors) {
        const payload = errors[errorCode];
        const text = this.errorsMapper[errorCode as ErrorFromValidatorType];
        if (text) {
          texts.push(text(payload));
        } else {
          texts.push('Verifica este campo por favor. Tienes un error en él.');
        }
      }
      return {
        display,
        errors: texts,
        target,
      };
    });
  }
}
