import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AdDirective } from '../../../core/ad/ad.directive';
import {
  EventResponse,
  MarkersModel,
  MinimalActionModel,
  TeyunaActionModel,
  TeyunaGeneralActionModel,
} from '../../../domain/models/actions.model';
import { TeyunaPresentationModel } from '../../../domain/models/presentation.model';
import { TeyunaSchemaDefinitionModel } from '../../../domain/models/schema-definition.model';
import { DynamicComponentService } from '../../services/dynamic-component.service';
import { DetailComponent } from '../detail/detail.component';
import { ListComponent } from '../list/list.component';

@Component({
  selector: 'tyn-crud-builder',
  templateUrl: './crud-builder.component.html',
  styleUrls: ['./crud-builder.component.scss'],
})
export class TeyunaComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  private combinedTriggers?: Observable<any>;

  /**
   * This is the action used for creating data to the model
   *
   * @type {MinimalActionModel}
   * @memberof ListComponent
   */
  @Input('create-action') createAction: TeyunaActionModel | undefined;
  /**
   * This function lets to get the data
   *
   * @memberof ListComponent
   */
  @Input('data-function') dataFunction!: (
    page: number,
    sizePage: number,
    definition: any
  ) => Observable<any>;
  /**
   * Model that configures the columns, tags, definitions and schema to be used.
   *
   *  columns: [
   *     {
   *       definition: string;
   *       tag: string;
   *     }
   *   ];
   *   tags: string[];
   *   schema: any;
   * @type {TeyunaSchemaDefinitionModel}
   * @memberof ListComponent
   */
  @Input() definition!: TeyunaSchemaDefinitionModel;
  /**
   * This is the action used for updating data to the model
   *
   * @type {MinimalActionModel}
   * @memberof ListComponent
   */
  @Input('delete-action') deleteAction!: MinimalActionModel;
  /**
   *Actions executed for all data inside the view
   *
   * @type {TeyunaActionModel[]}
   * @memberof ListComponent
   */
  @Input('general-actions') generalActions!: TeyunaGeneralActionModel[];
  /**
   *Current page of the data
   *
   * By default, it's zero, and continuely will be changed when the user interact on the Graphical interface.
   * @memberof ListComponent
   */
  @Input() initialPage = 0;
  @ViewChild('list', {
    read: AdDirective,
    static: false,
  })
  listContainer!: AdDirective;
  mode: 'list' | 'detail' = 'list';
  onChangePageNumber: BehaviorSubject<number>;
  onChangePageSize: BehaviorSubject<number>;
  @Input('on-data') onData?: Observable<any>;
  @Output('on-request-data') onRequestData: EventEmitter<any>;
  // @ViewChild('detail', {
  //   read: AdDirective,
  //   static: false,
  // })
  // detailContainer!: AdDirective;
  onRequestDataSubscription?: Subscription;
  /**
   * Configure how to visualize the information on the screen.
   *
   * This structure is recursive, that means that inside the screen, the algorithm makes
   * a tree with the components provided by the library.
   *
   * @type {TeyunaPresentationModel}
   * @memberof ListComponent
   */
  @Input() presentation!: TeyunaPresentationModel;
  /**
   * Actions executed per row
   *
   * @type {((TeyunaActionModel | string)[])}
   * @memberof ListComponent
   */
  @Input('row-actions') rowActions!: (TeyunaActionModel | string)[];
  /**
   * Actions executed per row
   *
   * @type {((TeyunaActionModel | string)[])}
   * @memberof ListComponent
   */
  @Input() markers!: MarkersModel[];
  /**
   *Wheter show or not the paginator element
   *
   * @memberof ListComponent
   */
  @Input('show-paginator') showPaginator = true;
  /**
   *Size of the page
   *
   * @memberof ListComponent
   */
  @Input('size-page') sizePage = 10;
  /**
   *Title of the page
   *
   * @memberof ListComponent
   */
  @Input() title?: string;
  @Input('no-data-message') noDataMessage?: string;
  @Input() triggers?: Observable<any>[];
  /**
   * This is the action used for updating data to the model
   *
   * @type {MinimalActionModel}
   * @memberof ListComponent
   */
  @Input('update-action') updateAction!: MinimalActionModel;

  @ViewChild('top', { static: true }) top?: TemplateRef<any>;
  @ContentChild('[top]', { static: false, read: ElementRef })
  topContent!: ElementRef;

  listComponent: any;
  constructor(
    public injector: Injector,
    public componentRef: ElementRef,
    private dynamicComponentService: DynamicComponentService,
    private router: Router
  ) {
    this.onChangePageNumber = new BehaviorSubject(this.initialPage);
    this.onChangePageSize = new BehaviorSubject(this.sizePage);
    this.onRequestData = new EventEmitter();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.createAction) {
      if (this.listComponent) {
        const listInstance: ListComponent = this.listComponent
          .instance as ListComponent;
        listInstance.createAction = this.createAction;
        listInstance.changeDetectorRef.detectChanges();
      }
    }
  }

  hide(elementRef: AdDirective): void {
    (
      elementRef.viewContainerRef.element.nativeElement as HTMLElement
    ).classList.add('hidden');
  }

  ngAfterViewInit(): void {
    this.render();
  }

  ngOnDestroy(): void {
    this.onRequestDataSubscription?.unsubscribe();
  }

  ngOnInit(): void {}

  private setListView(host: AdDirective) {
    host.viewContainerRef.detach(0);

    host.viewContainerRef.insert(this.listComponent.hostView);
    this.mode = 'list';
  }

  render(): void {
    const host: AdDirective = this.listContainer;

    this.listComponent = this.dynamicComponentService.createComponent(
      ListComponent,
      host.viewContainerRef,
      {
        initialPage: this.initialPage,
        definition: this.definition,
        presentation: this.presentation,
        rowActions: this.rowActions,
        generalActions: this.generalActions,
        createAction: this.createAction,
        updateAction: this.updateAction,
        title: this.title,
        noDataMessage: this.noDataMessage,
        deleteAction: this.deleteAction,
        dataFunction: this.dataFunction,
        sizePage: this.sizePage,
        showPaginator: this.showPaginator,
        onData: this.onData,
        markers: this.markers,
        triggers: this.triggers,
      }
    );
    const listInstance: ListComponent = this.listComponent
      .instance as ListComponent;
    // this.hide(detailHost);

    this.resolveTopContent(listInstance);
    this.onRequestDataSubscription?.unsubscribe();
    this.onRequestDataSubscription = listInstance.onRequestData.subscribe(
      (data) => {
        this.onRequestData.emit(data);
      }
    );

    listInstance.selectedItem.subscribe((dat) => {
      history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        this.setListView(host);
        window.onpopstate = null;
      };

      host.viewContainerRef.detach(0);
      this.mode = 'detail';

      const detailComponent = this.dynamicComponentService.createComponent(
        DetailComponent,
        host.viewContainerRef,
        {
          ...dat,
          definition: this.definition,
          presentation: this.presentation,
          updateAction: this.updateAction,
          displayRouting: true,
        },
        true
      );

      (detailComponent.instance as DetailComponent).updatedItem.subscribe(
        (data) => {
          let response:
            | Promise<EventResponse>
            | Observable<EventResponse>
            | void = undefined;

          if (data.mode == 'new') {
            if (this.createAction) {
              response = (
                this.createAction.event as (
                  item?: any
                ) => Promise<EventResponse>
              )(data.item);
            }
          } else if (data.mode == 'edit') {
            response = (
              this.updateAction.event as (item?: any) => Promise<EventResponse>
            )(data.item);
          }

          if (typeof response == 'undefined') {
            host.viewContainerRef.detach(0);
            host.viewContainerRef.insert(this.listComponent.hostView);
            this.mode = 'list';
          } else if (response instanceof Observable) {
            let subscription: Subscription | undefined = undefined;
            subscription = response.subscribe((data) => {
              const { navigate } = data;
              if (navigate) {
                host.viewContainerRef.detach(0);
                host.viewContainerRef.insert(this.listComponent.hostView);
                this.mode = 'list';
              }
              subscription?.unsubscribe();
            });
          } else if (response instanceof Promise) {
            response.then((data) => {
              const { navigate } = data;
              if (navigate) {
                host.viewContainerRef.detach(0);
                host.viewContainerRef.insert(this.listComponent.hostView);
                this.mode = 'list';
              }
            });
          }
        }
      );

      (detailComponent.instance as DetailComponent).goBack.subscribe((data) => {
        this.setListView(host);
        history.back();
      });
    });
  }

  private resolveTopContent(listInstance: ListComponent) {
    if (this.top) {
      listInstance.content = this.top;
      listInstance.changeDetectorRef.detectChanges();
    }
    return listInstance;
  }

  unhide(elementRef: AdDirective): void {
    (
      elementRef.viewContainerRef.element.nativeElement as HTMLElement
    ).classList.remove('hidden');
  }
}
