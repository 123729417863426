<section [attr.status]="status">
  <span class="material-icons" *ngIf="leftIcon">{{ leftIcon }}</span>
  <section>
    <input
      *ngIf="kind == 'input'"
      [attr.color]="color"
      [attr.status]="status"
      [attr.step]="step"
      [attr.type]="type"
      [attr.min]="min"
      [attr.max]="max"
      [placeholder]="placeholder || ''"
      [disabled]="formControl?.disabled || disabled"
    />
    

    <textarea
      *ngIf="kind == 'textarea'"
      [attr.color]="color"
      [attr.status]="status"
      [attr.type]="type"
      [attr.rows]="rows"
      [placeholder]="placeholder || ''"
      [disabled]="formControl?.disabled || disabled"
    ></textarea>
    <label>{{ label }}</label>
  </section>
  <span class="material-icons" *ngIf="rightIcon">{{ rightIcon }}</span>
</section>
<span *ngIf="hint" class="text-base">{{ hint }}</span>
