import { Injectable, ViewContainerRef } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class DynamicComponentService {
  constructor() {}

  createComponent(
    component: any,
    viewContainerRef: ViewContainerRef,
    data: any,
    clear = false
  ) {
    // const componentFactory =
    //   this.componentFactoryResolver.resolveComponentFactory<AdComponent>(
    //     component
    //   );

    if (clear) {
      viewContainerRef.clear();
    }
    // const createdComponent = viewContainerRef.createComponent(componentFactory);
    const createdComponent = viewContainerRef.createComponent(component);

    (createdComponent.instance as any).data = data;
    return createdComponent;
  }
}
