import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  ActionMode,
  TeyunaActionModel,
} from '../../../domain/models/actions.model';
// import { componentMap } from 'angular-components-library/component-mapper';
import { MatDialog } from '@angular/material/dialog';
import { AdDirective } from 'angular-components-library/core';
import {
  DirectValidatorType,
  IndirectValidatorType,
  TeyunaDirectValidator,
  TeyunaIndirectValidator,
  ValidatorsMapper,
} from '../../../core/validators/validators-mapper';
import { AdComponent } from '../../../domain/models/ad-item-model';
import {
  ComponentsType,
  TeyunaCondition,
  TeyunaPresentationModel,
} from '../../../domain/models/presentation.model';
import {
  Relationship,
  TeyunaSchemaDefinitionModel,
} from '../../../domain/models/schema-definition.model';
import { MandatoryVisualComponentsMap } from '../../../interfaces';
import { MANDATORY_VISUAL_COMPONENTS_TOKEN } from '../../../teyuna-injection-tokens';
import { ErrorsModalComponent } from '../../components/errors-modal/errors-modal.component';
import { ContainersTableByComponentManager } from './containers-table-by-component.manager';

export interface FormControlsByProperty {
  [propertyName: string]: FormControl<string | null>;
}

@Component({
  selector: 'tyn-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit, OnChanges {
  static route = 'detail';

  containersTableByComponentManager: ContainersTableByComponentManager;
  @ViewChild(AdDirective, { static: true }) adHost!: AdDirective;
  @Input() columns!: any;
  data!: any;
  @Input() definitions: any;
  formGroup: FormGroup;
  @Input() item: any;
  @Input() mode!: ActionMode;
  @Input() presentation: any;
  @Input() validator?: (formGroupValue: any) => {
    property: string;
    errors: ValidationErrors[];
  }[];
  repr!: string;

  @Input() tags: any;
  /**
   * This is the action used for updating data to the model
   *
   * @type {TeyunaActionModel}
   * @memberof ListComponent
   */
  @Input('update-action') updateAction!: TeyunaActionModel;
  @Output('updatedItem') updatedItem = new EventEmitter();
  @Output('go-back') goBack = new EventEmitter();
  @Input('display-routing') displayRouting = false;

  /**
   * Model that configures the columns, tags, definitions and schema to be used.
   *
   *  columns: [
   *     {
   *       definition: string;
   *       tag: string;
   *     }
   *   ];
   *   definitions: string[];
   *   tags: string[];
   *   schema: any;
   * @type {TeyunaSchemaDefinitionModel}
   * @memberof ListComponent
   */
  @Input() definition!: TeyunaSchemaDefinitionModel;
  propertiesDisplay: any = {};

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private dialog: MatDialog,
    @Inject(MANDATORY_VISUAL_COMPONENTS_TOKEN)
    public mandatoryVisualComponents: MandatoryVisualComponentsMap,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({});

    this.containersTableByComponentManager =
      new ContainersTableByComponentManager(
        this.changeDetectorRef,
        this.formGroup
      );

    this.formGroup.valueChanges.subscribe((formGroupValue: any) => {
      if (this.containersTableByComponentManager.isItAllowedToListen) {
      }
    });
  }

  public back() {
    this.goBack.emit({ item: this.formGroup.value, mode: this.data.mode });
  }

  extractRepresentation() {
    let values: string[] = [];
    if (this.definition) {
      for (const part of this.definition.schema.repr) {
        if (this.item) {
          if (part in this.item) {
            values.push(this.item[part]);
          } else {
            values.push(part);
          }
        }
      }
    }
    this.repr = values.join(' ');
  }

  init() {
    this.extractRepresentation();
    this.propertiesDisplay = {};
    // const group: FormControlsByProperty = {};
    let i = 0;
    this.containersTableByComponentManager.reset();
    this.adHost.viewContainerRef.clear();

    if ('component' in this.presentation) {
      this.render(this.adHost.viewContainerRef, this.presentation, 0);
    } else {
      if ('children' in this.presentation) {
        let i = 0;
        for (const child of this.presentation['children']) {
          this.render(this.adHost.viewContainerRef, child, i);
          i++;
        }
      }
    }
    setTimeout(() => {
      this.containersTableByComponentManager.start();
    }, 100);
    // this.formGroup = new FormGroup(group);
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (
        (changes.item && changes.item.currentValue) ||
        (changes.presentation && changes.presentation.currentValue) ||
        (changes.definition && changes.definition.currentValue)
      ) {
        this.init();
      }
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.updateAction = this.data.updateAction;
      this.item = this.data.item;
      this.definition = this.data.definition;
      this.presentation = this.data.presentation;
      this.mode = this.data.mode;
      this.displayRouting = this.data.displayRouting;
    }
    this.init();
  }
  logValidationErrors(formGroup: FormGroup) {
    const errors = Object.keys(formGroup.controls)
      .map((key) => {
        const control: FormControl | null = formGroup.get(key) as FormControl;

        if (control instanceof FormGroup) {
          this.logValidationErrors(control);
        }

        if (control!.errors) {
          const display = this.propertiesDisplay[key];

          const htmlElement = this.renderer.selectRootElement(`#${key}`, true);

          return {
            display,
            errors: control!.errors,
            property: key,
            target: htmlElement,
          };
        }
        return null;
      })
      .filter((x) => !!x);

    this.dialog.open(ErrorsModalComponent, {
      maxHeight: '80vh',
      minHeight: '50vh',
      panelClass: 'p-absolute',
      data: {
        errors,
      },
    });
  }
  public saveItem() {
    const newItem = this.formGroup.value;
    const item = { ...this.item };

    if (this.validator) {
      const errors = this.validator(newItem).map((error) => {
        const display = this.propertiesDisplay[error.property];
        const target = this.renderer.selectRootElement(
          `#${error.property}`,
          true
        );
        return {
          ...error,
          display,
          target,
        };
      });
      console.log(errors);

      if (errors.length > 0) {
        this.dialog.open(ErrorsModalComponent, {
          maxHeight: '80vh',
          minHeight: '50vh',
          panelClass: 'p-absolute',
          data: {
            errors,
          },
        });
      } else {
        this.updatedItem.emit({ item: newItem, mode: this.mode });
      }
    } else {
      if (this.formGroup.valid) {
        for (const key in newItem) {
          if (Object.prototype.hasOwnProperty.call(newItem, key)) {
            const element = newItem[key];
            item[key] = element;
          }
        }
        this.updatedItem.emit({ item, mode: this.mode });
      } else {
        this.logValidationErrors(this.formGroup);
      }
    }
  }

  createComponent(
    component: any,
    viewContainerRef: ViewContainerRef,
    data: any,
    clear = false
  ) {
    if (clear) {
      viewContainerRef.clear();
    }
    const ref = viewContainerRef.createComponent<AdComponent>(component);
    ref.instance.data = data;
    return ref;
  }

  private render(
    host: ViewContainerRef,
    presentationModel: TeyunaPresentationModel,
    index = 0
  ) {
    const formControlData = {
      value: '',
      disabled: this.mode == 'detail',
    };

    let formControl;
    let data: any = { ...presentationModel };
    let relation: Relationship | undefined;
    let property: string | undefined;
    if ('property' in presentationModel) {
      property = presentationModel['property'];
      this.propertiesDisplay[property] =
        presentationModel['configuration']['label'];
      const validators = presentationModel['validators'];

      const validatorsTemp: ValidatorFn[] = [];

      if (validators) {
        for (const validatorKey of validators) {
          if (typeof validatorKey === 'string') {
            const validatorInstance = (
              ValidatorsMapper as TeyunaDirectValidator
            )[validatorKey as DirectValidatorType];
            if (validatorInstance) {
              validatorsTemp.push(validatorInstance);
            }
          } else {
            const realValidator = validatorKey.validator;
            const params = validatorKey.params;
            const validatorInstance = (
              ValidatorsMapper as TeyunaIndirectValidator
            )[realValidator as IndirectValidatorType];
            if (validatorInstance) {
              const f: ValidatorFn = validatorInstance(...params);
              validatorsTemp.push(f);
            }
          }
        }
      }

      formControlData.value =
        this.item && property in this.item ? this.item[property] : '';

      formControl = new FormControl(formControlData, {
        validators: validatorsTemp,
      });

      this.formGroup.setControl(property, formControl);

      if (this.definition && this.definition.relationship) {
        relation = (this.definition.relationship as Array<any>)
          .filter((item) => {
            return item.from == property;
          })
          .pop();
      }

      data = {
        ...data,
        formControl: formControl,
        dataFunction: relation?.dataFunction || null,
        relativeProperty: relation?.to || relation?.from || null,
        itemValue:
          this.item && property in this.item ? this.item[property] : '',
      };

      // group[property] = formControl;
    }
    const component: ComponentsType = presentationModel['component'];
    const condition: TeyunaCondition<any> | undefined =
      presentationModel['condition'];
    const componentClass = this.mandatoryVisualComponents[component];
    const newComponent = this.createComponent(componentClass, host, data);

    this.containersTableByComponentManager.register(
      newComponent,
      host,
      index,
      property,
      formControl,
      condition
    );

    if (data['property']) {
      const nativeElement = (newComponent.instance as unknown as any).elementRef
        .nativeElement;
      if (typeof nativeElement === 'object' && nativeElement.setAttribute) {
        (nativeElement as HTMLElement).setAttribute('id', data['property']);
      }
    }

    if ('children' in presentationModel) {
      let i = 0;
      for (const child of presentationModel['children']) {
        const newRef = newComponent.instance.adHost;
        this.render(newRef.viewContainerRef, child, i);
        i++;
      }
    }
    return newComponent;
  }
}
