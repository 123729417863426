<div
  class="content d-flex flex-wrap m-auto col-12 col-md-11 col-lg-11 col-xl-10"
>
  <section class="col-12 col-md-8 col-xl-9">
    <section *ngFor="let post of $posts | async" class="post-card">
      <div class="actions" *ngIf="user">
        <acl-button (click)="goToEditing(post.id)" size="xxsmall"
          >Editar</acl-button
        >
        <acl-button color="danger" (click)="deletePost(post)" size="xxsmall"
          >Eliminar</acl-button
        >
      </div>

      <img [src]="post.img" alt="Imagen del post" class="img-fluid" />

      <header class="py-2 mb-2">
        <h4>{{ post.title }}</h4>
        <h5 *ngIf="post.subtitle">{{ post.subtitle }}</h5>
      </header>
      <p class="my-3">{{ post.summary }}</p>
      <footer>
        <acl-button
          kind="outline"
          color="primary"
          size="xsmall"
          [routerLink]="['/post', post.id]"
        >
          Leer más
        </acl-button>
      </footer>
    </section>
  </section>

  <aside class="col-12 col-md-4 col-xl-3">
    <header class="py-3 text-center" *ngIf="$posts">
      <h2 class="m-0">
        <strong> Temas </strong>
      </h2>
    </header>
    <div class="mb-4 px-1">
      <div *ngIf="user" class="my-3 d-flex justify-content-center">
        <acl-button size="small" (click)="goTo()"> Crear Nuevo </acl-button>
      </div>

      <mat-list class="mx-2">
        <mat-list-item *ngFor="let post of $posts | async">
          <mat-icon matListItemIcon>arrow_forward</mat-icon>
          <a matListItemTitle [routerLink]="['/post', post.id]">{{
            post.title
          }}</a>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="p-3">
      <h3><strong> Acerca del Blog </strong></h3>
      <p>
        En este blog encontrarás información sobre servicios, herramientas y
        consejos para empresas del sector, con tecnologías avanzadas como
        agendamiento automatizado, gestión de clientes y empleados para
        optimizar la operación y mejorar la eficiencia.
      </p>
    </div>
  </aside>
</div>
