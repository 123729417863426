import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'primeng/carousel';
import { DockModule } from 'primeng/dock';
import { PanelModule } from 'primeng/panel';
import { TagModule } from 'primeng/tag';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MegaMenuModule } from 'primeng/megamenu';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImageModule } from 'primeng/image';
import { RadioButtonModule } from 'primeng/radiobutton';

//import { AngularFireModule } from '@angular/fire/compat';
//import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AclCardComponent } from 'angular-components-library/card';
import { AclChoiceInputComponent } from 'angular-components-library/choice-input';
import { AclColComponent } from 'angular-components-library/col';
import { AclContainerComponent } from 'angular-components-library/container';
import { AclDynamicInputComponent } from 'angular-components-library/dynamic-input';
import { AclFileComponent } from 'angular-components-library/file';
import { AclInputComponent } from 'angular-components-library/input';
import { AclInputDateComponent } from 'angular-components-library/input-date';
import { AclJsonInputComponent } from 'angular-components-library/json-input';
import { AclLabelComponent } from 'angular-components-library/label';
import { AclListInputComponent } from 'angular-components-library/list-input';
import { AclRowComponent } from 'angular-components-library/row';
import { AclSelectComponent } from 'angular-components-library/select';
import { AclSwitchComponent } from 'angular-components-library/switch';
import { AclTimeInputComponent } from 'angular-components-library/time-input';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { LottieModule } from 'ngx-lottie';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { environment } from 'src/environments/environment';
import { TeyunaModule } from 'teyuna';
import { ComponentsModule } from './components/components.module';
import { playerFactory } from './views/player-factory.lottie';
import { ViewsModule } from './views/views.module';

import { StoreModule } from '@ngrx/store';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { BlogModule } from './features/blog/blog.module';
import { ANALITICS_TOKEN, AUTH_TOKEN, FIREBASE_TOKEN, FIRESTORE_TOKEN, STORAGE_TOKEN } from './general.config';



const app = initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BlogModule,
    ComponentsModule,
    LottieModule.forRoot({ player: playerFactory }),
    //AngularFireModule.initializeApp(environment.firebase),

    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    ButtonModule,
    TagModule,
    PanelModule,
    CardModule,
    BrowserAnimationsModule,
    DockModule,
    RadioButtonModule,
    StoreModule.forRoot([]),
    TeyunaModule.forRoot({
      visualComponents: {
        card: AclCardComponent,
        col: AclColComponent,
        container: AclContainerComponent,
        input: AclInputComponent,
        label: AclLabelComponent,
        row: AclRowComponent,
        select: AclSelectComponent,
        file: AclFileComponent,
        'input-date': AclInputDateComponent,
        'time-input': AclTimeInputComponent,
        'dynamic-input': AclDynamicInputComponent,
        switch: AclSwitchComponent,
        'list-input': AclListInputComponent,
        'choice-input': AclChoiceInputComponent,
        'json-input': AclJsonInputComponent,
      },
    }),
    CommonModule,
    FormsModule,
    MegaMenuModule,
    ImageModule,
    ViewsModule,
    MenubarModule,
  ],
  providers: [
    {
      provide: FIREBASE_TOKEN,
      useValue: app,
    },
    {
      provide: FIRESTORE_TOKEN,
      useValue: getFirestore(app),
    },
    {
      provide: AUTH_TOKEN,
      useValue: getAuth(app),
    },
    {
      provide: STORAGE_TOKEN,
      useValue: getStorage(app),
    },
    {
      provide: ANALITICS_TOKEN,
      useValue: getAnalytics(app),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
