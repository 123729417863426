import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TeyunaActionModel } from './domain/models/actions.model';
import { TeyunaPresentationModel } from './domain/models/presentation.model';
import { TeyunaSchemaDefinitionModel } from './domain/models/schema-definition.model';
import { MandatoryVisualComponents } from './interfaces';

export const INITIAL_PAGE_TOKEN = new InjectionToken<number>(
  'Initial page for the module. This is zero by default.'
);
export const DEFINITION_TOKEN = new InjectionToken<TeyunaSchemaDefinitionModel>(
  'Labels, properties and columns for the model that you have to use.'
);

export const PRESENTATION_TOKEN = new InjectionToken<TeyunaPresentationModel>(
  'Represents how to display the information.'
);

export const ROW_ACTIONS_TOKEN = new InjectionToken<(TeyunaActionModel | string)[]>(
  'Actions per file'
);

export const GENERAL_ACTIONS_TOKEN = new InjectionToken<TeyunaActionModel[]>(
  'Actions for a group of items.'
);

export const CREATE_ACTION_TOKEN = new InjectionToken<TeyunaActionModel>(
  'Action used for creating a new item.'
);

export const DATA_FUNCTION_ACTION_TOKEN = new InjectionToken<
(page: number, sizePage:number, definition: TeyunaSchemaDefinitionModel) => Observable<any>
>('Function that lets you to get the data.');


export const SIZE_PAGE_TOKEN = new InjectionToken<number>(
    'Size of each page in the table. It is 15 by default.'
  );
export const MANDATORY_VISUAL_COMPONENTS_TOKEN = new InjectionToken<MandatoryVisualComponents>(
    'Visual components to use in the crud builder. It is 15 by default.'
  );