import { Injectable } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
  WhereFilterOp,
} from 'firebase/firestore';
import {
  deleteObject,
  FirebaseStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { from, Observable, of } from 'rxjs';
import { v4 } from 'uuid';
import { Entity } from './entity';
export interface fil {
  operator: WhereFilterOp;
  value: any;
}
export interface Filters {
  or?: {
    [property: string]: fil | fil[];
  };
  and?: {
    [property: string]: fil | fil[];
  };
}
@Injectable()
export class GeneralService<T extends Entity> {
  db!: Firestore;
  parentCollections!: string[];
  collection!: string;
  storage!: FirebaseStorage;

  docsByPage: {
    [page: number]: QueryDocumentSnapshot<any> | null;
  };

  constructor() {
    this.docsByPage = {};
  }
  dateConverter: FirestoreDataConverter<any> = {
    toFirestore: (data) => data,
    fromFirestore: (snapshot) => {
      const data = snapshot.data();
      const response: any = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];

          if (value instanceof Timestamp) {
            response[key] = value.toDate();
          } else {
            response[key] = value;
          }
        }
      }

      return response;
    },
  };

  init(
    db: Firestore,
    storage: FirebaseStorage,
    parentCollections: string[],
    collection: string
  ) {
    this.db = db;
    this.parentCollections = parentCollections;
    this.storage = storage;
    this.collection = collection;
  }

  getPaginatedItems(
    docIds: string[],
    page: number,
    limitNum: number,
    propertyOrder = 'id'
  ): Observable<T[] | null> {
    const colRef = this.getCollectionRef(
      this.parentCollections,
      docIds,
      this.collection
    )?.withConverter(this.dateConverter);
    if (colRef) {
      let q = query(colRef, orderBy(propertyOrder), limit(limitNum));

      const lastDoc = this.getLastDoc(page);

      if (lastDoc) {
        q = query(
          colRef,
          orderBy(propertyOrder),
          startAfter(lastDoc),
          limit(limitNum)
        );
      }

      return from(
        getDocs(q).then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            this.docsByPage[page] =
              querySnapshot.docs[querySnapshot.docs.length - 1];
          }
          return querySnapshot.docs.map(this.merger<T>);
        })
      );
    }
    return of(null);
  }

  getLastDoc(page: number) {
    const prev = page - 1;
    if (this.docsByPage[prev]) {
      return this.docsByPage[prev];
    }
    return null;
  }
  private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  merger<A>(c: QueryDocumentSnapshot<DocumentData>) {
    return {
      ...c.data(),
      id: c.id,
    } as A;
  }
  getExtension(base64: string) {
    const mime = this.getMime(base64);
    if (mime) {
      const mimeType: any = {
        'image/jpeg': 'jpg',
        'image/webp': 'webp',
        'image/jpg': 'jpg',
        'image/png': 'png',
        'text/html': 'html',
      };
      return mimeType[mime];
    }
    return null;
  }
  getMime(base64: string) {
    if (base64) {
      const h = base64.split(';')[0].replace('data:', '');

      return h;
    }
    return null;
  }
  createFile(base64String: string, path: string) {
    const [a, base64] = base64String.split(',');
    const h = a.replace('data:', '').replace('base64', '').replace(';', '');

    const imageBlob = this.base64ToBlob(base64, h);
    const storageRef = ref(this.storage, path);
    return uploadBytes(storageRef, imageBlob);
  }
  deleteFile(path: string) {
    const storageRef = ref(this.storage, path);
    return deleteObject(storageRef);
  }

  getURL(path: string) {
    const t = path.replace(/\//g, '%2F');
    return `https://firebasestorage.googleapis.com/v0/b/client-management-d98df.firebasestorage.app/o/${t}?alt=media`;
  }

  protected joinCollAndDocs(
    db: Firestore,
    parentCollections: string[],
    parentDocIds: string[],
    collection: string
  ) {
    let t = ``;
    let i = 0;
    if (
      !db ||
      !parentCollections ||
      parentCollections.length != parentDocIds.length
    ) {
      return null;
    }

    for (const pc of parentCollections) {
      t += `/${pc}/${parentDocIds[i]}`;
      i++;
    }
    t += `/${collection}`;
    return t;
  }
  generateId() {
    return v4().replace(/-/g, '').substring(0, 20);
  }

  getDocRef(
    parentCollections: string[],
    parentDocIds: string[],
    collectionName: string,
    lastId?: string
  ) {
    let t = this.joinCollAndDocs(
      this.db,
      parentCollections,
      parentDocIds,
      collectionName
    );
    if (t == null) {
      return null;
    }
    if (lastId) {
      t += `/${lastId}`;
    }
    let collectionReference = doc(this.db, t);
    return collectionReference;
  }
  protected getCollectionRef(
    parentCollections: string[],
    parentDocIds: string[],
    collectionName: string
  ) {
    let t = this.joinCollAndDocs(
      this.db,
      parentCollections,
      parentDocIds,
      collectionName
    );
    if (t == null) {
      return null;
    }
    let collectionReference = collection(this.db, t!);
    return collectionReference;
  }

  getAll(docIds: string[]): Observable<T[] | null> {
    return this._getAll<T>(this.parentCollections, docIds, this.collection);
  }
  protected _getAll<TT>(
    parentCollections: string[],
    docIds: string[],
    collectionName: string
  ): Observable<TT[] | null> {
    const ref = this.getCollectionRef(
      parentCollections,
      docIds,
      collectionName
    )?.withConverter(this.dateConverter);
    if (ref) {
      return from(
        getDocs(ref).then((snapshot) => {
          return snapshot.docs.map(this.merger<TT>);
        })
      );
    }
    return of(null);
  }

  save(docIds: string[], obj: T, ...args: any): Observable<T | null> {
    return this._save<T>(
      this.parentCollections,
      docIds,
      this.collection,
      obj,
      args
    );
  }

  private removeUndefinedValues(obj: any): any {
    if (typeof obj !== 'object' || obj instanceof Date || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((x) => this.removeUndefinedValues(x));
    }
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];

      if (value !== undefined && value !== 'undefined') {
        acc[key] =
          typeof value === 'object' && value !== null
            ? this.removeUndefinedValues(value)
            : value;
      }

      return acc;
    }, {} as any);
  }

  _save<TT extends Entity>(
    parentCollections: string[],
    docIds: string[],
    collectionName: string,
    obj: TT,
    ...args: any
  ): Observable<TT | null> {
    if (!obj.id) {
      obj.id = this.generateId();
    }
    const ref = this.getDocRef(
      parentCollections,
      docIds,
      collectionName,
      obj.id
    );
    if (ref) {
      const cleanedObj = this.removeUndefinedValues(obj);
      return from(
        setDoc(ref, cleanedObj).then((doc) => {
          return obj;
        })
      );
    }
    return of(null);
  }
  _edit<TT extends Entity>(
    parentCollections: string[],
    docIds: string[],
    collectionName: string,
    obj: TT
  ): Observable<TT | null> {
    const ref = this.getCollectionRef(
      parentCollections,
      docIds,
      collectionName
    );
    if (ref) {
      const doca = doc(ref, obj.id);
      const cleanedObj = this.removeUndefinedValues(obj);

      return from(
        updateDoc(doca, cleanedObj as any).then((doc) => {
          return cleanedObj;
        })
      );
    }
    return of(null);
  }
  edit(docIds: string[], obj: T): Observable<T | null> {
    return this._edit<T>(this.parentCollections, docIds, this.collection, obj);
  }
  _getById<TT>(
    parentCollections: string[],
    docIds: string[],
    collectionName: string,
    id: string
  ): Observable<TT | null> {
    const ref = this.getCollectionRef(
      parentCollections,
      docIds,
      collectionName
    );

    if (ref) {
      return from(
        getDoc(doc(ref, id)).then((snapshot) => {
          return { id: snapshot.id, ...snapshot.data() } as TT;
        })
      );
    }
    return of(null);
  }
  getById(docIds: string[], id: string): Observable<T | null> {
    return this._getById(this.parentCollections, docIds, this.collection, id);
  }
  _delete(
    parentCollections: string[],
    docIds: string[],
    collectionName: string,
    id: string
  ): Observable<boolean> {
    const ref = this.getCollectionRef(
      parentCollections,
      docIds,
      collectionName
    );
    if (ref) {
      const doca = doc(ref, id);

      return from(
        deleteDoc(doca).then((doc) => {
          return true;
        })
      );
    }
    return of(false);
  }
  delete(docIds: string[], id: string): Observable<boolean> {
    return this._delete(this.parentCollections, docIds, this.collection, id);
  }

  filter(docIds: string[], filters: Filters): Observable<T[]> {
    const innerFilters: QueryFieldFilterConstraint[] = [];
    const innerOrFilters: QueryFieldFilterConstraint[] = [];

    const queries = [];

    const ref = this.getCollectionRef(
      this.parentCollections,
      docIds,
      this.collection
    );
    if (ref) {
      let mainQuery = query(ref);

      if (filters.and && Object.keys(filters.and).length) {
        for (const property in filters.and) {
          const element = filters.and[property];
          if (Array.isArray(element)) {
            for (const fi of element) {
              innerFilters.push(where(property, fi.operator, fi.value));
            }
          } else {
            innerFilters.push(where(property, element.operator, element.value));
          }
        }
        mainQuery = query(mainQuery, ...innerFilters);
      }

      if (filters.or && Object.keys(filters.or).length) {
        for (const key in filters.or) {
          const element = filters.or[key];
          if (Array.isArray(element)) {
            for (const fi of element) {
              innerOrFilters.push(where(key, fi.operator, fi.value));
            }
          } else {
            innerOrFilters.push(where(key, element.operator, element.value));
          }
        }

        if (mainQuery) {
          for (const iof of innerOrFilters) {
            queries.push(query(mainQuery, iof));
          }
        } else {
          for (const iof of innerOrFilters) {
            queries.push(query(ref, iof));
          }
        }
      }

      if (queries.length == 0) {
        queries.push(mainQuery);
      }

      return from(
        Promise.all(queries.map((x) => getDocs(x)))
          .then((data) => {
            return data
              .map((d) => {
                return d.docs.map((doc) => {
                  return { id: doc.id, ...doc.data() } as T;
                });
              })

              .reduce((acc: T[], curr) => {
                acc.push(...curr);
                return acc;
              }, [] as T[]);
          })
          .catch((err) => {
            return [];
          })
      );
    } else {
      return of([]);
    }
  }
}
