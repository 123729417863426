import {
  ChangeDetectorRef,
  ComponentRef,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AdComponent } from '../../../domain/models/ad-item-model';
import { TeyunaCondition } from '../../../domain/models/presentation.model';

export class ContainersTableByComponentManager {
  isItAllowedToListen: boolean = false;

  myComponents!: Map<
    ComponentRef<AdComponent>,
    {
      host: ViewContainerRef;
      condition?: TeyunaCondition;
      index: number;
    }
  >;

  changesSubscriptions: {
    [propertyName: string]: {
      formControl: FormControl;
    };
  } = {};
  innerDOM!: Map<
    ViewContainerRef,
    {
      component: ComponentRef<AdComponent>;
      index: number;
    }[]
  >;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,

    private formGroup: FormGroup
  ) {
    this.init();
  }

  register(
    component: ComponentRef<AdComponent>,
    host: ViewContainerRef,
    index: number,
    propertyName?: string,
    formControl?: FormControl,
    condition?: TeyunaCondition<any>
  ): void {
    this.myComponents.set(component, {
      host,
      condition,
      index,
    });

    if (!this.innerDOM.get(host)) {
      this.innerDOM.set(host, []);
    }
    const temp = this.innerDOM.get(host);

    temp?.push({ component, index });

    if (formControl && propertyName) {
      this.changesSubscriptions[propertyName] = {
        formControl: formControl,
      };
    }
  }
  enable() {
    this.isItAllowedToListen = true;
  }
  private init() {
    this.myComponents = new Map();
    this.innerDOM = new Map();
    this.isItAllowedToListen = false;
    this.formGroup.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      if (this.isItAllowedToListen) {
        this.changeHandler();
      }
    });
  }
  reset() {
    this.init();
  }

  start() {
    this.changeHandler();
    this.enable();
  }
  changeHandler() {
    const formGroupValue = this.formGroup.value;
    const keys = this.myComponents.keys();
    for (const componentRef of keys) {
      const config = this.myComponents.get(componentRef)!;
      const { host, index: originalIndex, condition } = config;

      if (condition) {
        let isAbleToAdd = condition(formGroupValue);

        if (!isAbleToAdd) {
          const myIndexINView = host.indexOf(componentRef.hostView);

          if (myIndexINView && myIndexINView > -1) {
            host.detach(myIndexINView);
          }
        } else {
          const myIndexINView = host.indexOf(componentRef.hostView);

          if (myIndexINView == -1) {
            // console.log('------------------------');
            // console.log();
            // console.log();
            // console.log('Component ', componentRef);

            const [prev, next] = this.searchLimits(originalIndex, host);
            // console.log('Limits  ', [prev, next]);

            if (prev > -1) {
              // console.group('Información');
              // console.log('Original index: ' + originalIndex);
              // console.log(host);
              // console.log(host.length);
              // console.groupEnd();

              host.insert(componentRef.hostView, prev + 1);
            }
          }
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }
  searchLimits(myIndex: number, host: ViewContainerRef) {
    const brothers = this.innerDOM.get(host) ?? [];

    let next = myIndex + 1;
    if (brothers.length) {
      let brotherConfig = brothers[next];
      if (brotherConfig) {
        let found = host.indexOf(brotherConfig.component.hostView) != -1;
        while (next < brothers.length && !found) {
          brotherConfig = brothers[next];
          found = host.indexOf(brotherConfig.component.hostView) != -1;
          next++;
        }
        if (!found) {
          next = -1;
        }
      }
    }
    let pivot = myIndex - 1;
    let prev = -1;
    if (brothers.length) {
      // console.log('myIndex', myIndex);
      // console.log(brothers);

      let brotherConfig = brothers[pivot];
      if (brotherConfig) {
        // console.log(brotherConfig);

        let foundIndex = host.indexOf(brotherConfig.component.hostView);

        // console.log('found ', foundIndex);

        while (pivot >= 0 && foundIndex == -1) {
          pivot--;
          brotherConfig = brothers[pivot];
          // console.log(brotherConfig);
          foundIndex = host.indexOf(brotherConfig.component.hostView);
          // console.log('found ', foundIndex);
        }
        prev = foundIndex;
      }
    }

    return [prev, next];
  }
}
