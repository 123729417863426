import { AbstractControl, ValidationErrors } from '@angular/forms';

export function haveToBeDefined(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  return value !== undefined && value !== null
    ? null
    : {
        haveToBeDefined: {
          currentValue: value,
        },
      };
}
