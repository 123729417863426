import { Component, OnInit } from '@angular/core';

export type ParagraphConfiguration =
  | {
      mainText: string;
      subtitle: string;
      title: string;
      imgParagraph: string;
      list: string[];
    }
  | string;

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent implements OnInit {
  static kind = 'paragraph';
  data?: ParagraphConfiguration;

  mainText?: string;
  list?: string[];
  subtitle?: string;
  title?: string;
  imgParagraph?: string;

  ngOnInit(): void {
    if (this.data) {
      if (typeof this.data == 'string') {
        this.mainText = this.data;
      } else {
        this.mainText = this.data.mainText;
        this.list = this.data.list;
        this.subtitle = this.data.subtitle;
        this.title = this.data.title;
        this.imgParagraph = this.data.imgParagraph;
      }
    }
  }
}
