<section class="tyn-list-body">
  <h1 *ngIf="title" class="my-2 px-2">{{ title }}</h1>
  <div *ngIf="content" class="tyn-list-body-content w-100 my-1">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <div
    class="tyn-list-body-table"
    *ngIf="
      visualItems[this.page] && visualItems[this.page].length;
      else noDataTeyuna
    "
  >
    <table *ngIf="definition && pageIsValid() && innerDefinitions">
      <thead>
        <tr>
          <ng-container *ngIf="generalActions && generalActions.length">
            <th>#</th>
          </ng-container>
          <th *ngFor="let column of definition.columns">
            {{ column.tag }}
          </th>

          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let element of visualItems[this.page]">
          <tr>
            <td *ngIf="generalActions && generalActions.length">
              <input
                type="checkbox"
                (change)="updateItem($event, element)"
                name=""
                id=""
              />
            </td>
            <td *ngFor="let column of definition.columns">
              <div [innerHTML]="element[column.definition]"></div>
            </td>
            <td>
              <div class="d-flex h-100 w-100 justify-content-center">
                <div *ngIf="innerRowActions" class="row flex-nowrap">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon> more_vert </mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let action of innerRowActions">
                      <button
                        *ngIf="isActionEnabled(action, element) | async"
                        mat-menu-item
                        (click)="execute(action, element)"
                      >
                        <mat-icon *ngIf="action.icon">{{
                          action.icon
                        }}</mat-icon>
                        {{ action.display }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </div>
                <button
                  *ngIf="presentation"
                  mat-icon-button
                  (click)="goToDetail('detail', element)"
                >
                  <mat-icon> arrow_forward_ios </mat-icon>
                </button>
              </div>
            </td>
          </tr>
          <tr
            class="markers-container"
            *ngIf="
              generatedMarkers &&
              generatedMarkers[element[definition.schema.key]]?.length
            "
          >
            <td [attr.colspan]="innerDefinitions.length">
              <div class="example-element-detail">
                <div class="marker-container">
                  <span
                    *ngFor="
                      let marker of generatedMarkers[
                        element[definition.schema.key]
                      ]
                    "
                    class="marker"
                    [ngStyle]="{
                      'background-color': (marker | async)?.backgroundColor,
                      'border-color': (marker | async)?.color,
                      color: (marker | async)?.color
                    }"
                  >
                    <mat-icon class="" *ngIf="(marker | async)?.icon">
                      {{ (marker | async)!.icon }}</mat-icon
                    >
                    {{ (marker | async)?.display }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <ng-template #noDataTeyuna>
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <h2>{{ noDataMessage }}</h2>
    </div>
  </ng-template>
</section>

<footer
  *ngIf="
    showPaginator || (generalActions && generalActions.length) || createAction
  "
  class="tyn-list-footer d-flex w-100"
>
  <section
    (click)="goToDetail('new', {})"
    role="button"
    class="create-action"
    *ngIf="createAction"
  >
    <mat-icon>add</mat-icon>
    <span>Nuevo</span>
  </section>
  <section class="general-actions">
    <div
      *ngFor="let action of generalActions"
      class="col d-flex align-items-center"
    >
      <button
        class="general-action m-2 d-flex align-items-center"
        [ngStyle]="{
      'background-color': action.backgroundColor || 'initial',
      'color': action.color || 'initial',
      'border': '1px solid '+  (action.color || 'initial'),
      }"
        (click)="executeMany(action)"
      >
        <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
        <span>
          {{ action.display }}
        </span>
      </button>
    </div>
  </section>
  <section class="paginator">
    <section *ngIf="visualItems[this.page]" class="total">
      Total:
      {{ visualItems[this.page].length }}
    </section>
    <section *ngIf="showPaginator" class="paginator-controls">
      <mat-icon *ngIf="page > 0" (click)="goToPage(-page)" role="button"
        >keyboard_double_arrow_left</mat-icon
      >
      <section class="paginator-pages">
        <div (click)="goToPage(-1)" role="button" *ngIf="page - 1 > -1">
          {{ page }}
        </div>
        <div class="active-page">
          {{ nanValidator(page + 1) }}
        </div>
        <mat-icon class="not-charged" (click)="goToPage(1)" role="button"
          >keyboard_arrow_right</mat-icon
        >
        <div *ngIf="lastPage > page" role="button" [matMenuTriggerFor]="menu">
          ...
        </div>
        <mat-menu #menu="matMenu">
          <ng-container
            *ngFor="let num of [].constructor(lastPage); let i = index"
          >
            <button
              *ngIf="i != page"
              mat-menu-item
              (click)="goToPage(i - page)"
            >
              {{ nanValidator(i + 1) }}
            </button>
          </ng-container>
        </mat-menu>
        <!-- {{ page + 1 }} <small>de</small> {{ lastPage + 1 }} -->
      </section>
      <mat-icon
        *ngIf="lastPage != page"
        (click)="goToPage(lastPage - page)"
        role="button"
        >keyboard_double_arrow_right</mat-icon
      >
    </section>
  </section>
</footer>
