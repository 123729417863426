import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-support-plans',
  templateUrl: './support-plans.component.html',
  styleUrls: ['./support-plans.component.scss'],
})
export class SupportPlansComponent {
  @Output('on-selected-plan') onSelectedPlan: EventEmitter<boolean[]>;
  plansData = {
    plans: [
      {
        name: 'Estándar',
        description: 'Perfecto para empresas con pocos cambios anualmente',
        features: {
          annualModifications: 6,
          additionalModificationsPerYear: '$ 35.000 por hora',
          price: '$ 200.000 anual',
        },
      },
      {
        name: 'Plus',
        description:
          'Ideal para empresas con más trayectoria y presencia en el mercado',
        features: {
          annualModifications: 15,
          additionalModificationsPerYear: '$ 35.000 por hora',
          price: '$ 500.000 anual',
        },
      },
      {
        name: 'Negocios',
        description: 'Excelente para empresas con gran dinamismo',
        features: {
          annualModifications: 30,
          additionalModificationsPerYear: '$ 33.000 por hora',
          price: '$ 900.000 anual',
        },
      },
      {
        name: 'Empresas',
        description: 'Ideal para empresas con alta generación de contenido',
        features: {
          annualModifications: 60,
          additionalModificationsPerYear: '$ 28.000 por hora',
          price: "$ 1'560.000 anual",
        },
      },
    ],
    additionalNotes: {
      hourlyRateForModificationsOutsidePlan: '$ 40.000',
      note: 'Los cambios que no usen en el periodo de un año no serán redimibles en el siguiente año de suscripción.',
    },
  };

  selectedPlan: any;
  plansForTable = [];
  constructor() {
    this.onSelectedPlan = new EventEmitter();
  }
  onChangeCheckbox($event: any, index: number) {
    if ($event.target.checked) {
      this.selectedPlan = this.plansData.plans[index];
      this.onSelectedPlan.emit(this.selectedPlan);
    }
  }
}
