import { Component } from '@angular/core';

@Component({
  selector: 'app-built-in-aspects',
  templateUrl: './built-in-aspects.component.html',
  styleUrls: ['./built-in-aspects.component.scss'],
})
export class BuiltInAspectsComponent {
  aspects = [
    {
      // src\app\views\services\landing-service\built-in-aspects\built-in-aspects.component.html
      img: '../../../../../assets/views/landing-service/code-repo.png',
      label: 'Repositorio de Código',
      description:
      'Tendrás control total sobre el código de tu sitio, lo que te permitirá hacer modificaciones y adaptaciones de acuerdo con las necesidades de tu negocio. ',
      faqs: [
        {
          question:
          '¿Quiere decir que tú tienes que modificar directamente el código?',
          answer:
          'No, tú eres el dueño y nosotros somos los profesionales que lo editamos y gestionamos',
        },
      ],
    },
    {
      img: '../../../../../assets/views/landing-service/deploy.png',
      label: 'Publicación automática',
      description:
      'Implementa y actualiza tu sitio de manera automatizada, reduciendo el tiempo de espera y asegurando que siempre esté a la vanguardia.',
      faqs: [
        {
          question:
          '¿La actualización la tengo que hacer yo?',
          answer:
          'No, cuando agendamos la modificación de tu sitio, al momento de terminar te informamos y se publicará automáticamente una vez apruebes.',
        },
      ],
    },
    {
      img: '../../../../../assets/views/landing-service/landing-page.png',
      label: 'Contenido',
      description:
      'Tendrás la ventaja de tener muchos componentes que puedes usar en tu sitio, personalizándolos para que encajen con tu marca',
      faqs: [
        {
          question:
            '¿Las plantillas que tienen se pueden adaptar a mi negocio?',
          answer:
            'Diseñamos nuestras plantillas para parametrizarlas de acuerdo a las necesidades de nuestros clientes, así, puedes tener tu sitio personalizado.',
        },
        {
          question:
            '¿El sitio sirve para dispositivos móviles?',
          answer:
            'Diseñamos nuestras plantillas para que se adapten al dispositivo desde donde se visualiza, así que con nosotros tienes asegurado un comportamiento responsivo.',
        },
        {
          question:
            'Si tengo una necesidad puntual de un componente que ustedes no tienen ¿Se puede adicionar?',
          answer:
            'Cuando se presentan estos casos, siempre y cuando el componente sea de carácter informativo, lo diseñamos y lo ingresamos a nuestro catálogo. Si por el contrario es una necesidad que se sale del ámbito informativo, ya estariamos hablando de un desarrollo a la medida.',
        },
      ],
    },
  ];
}
