import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  from,
  of,
  zip,
} from 'rxjs';
import { defaultIfEmpty, filter, map, mergeMap } from 'rxjs/operators';
import { AdDirective } from '../../../core/ad/ad.directive';
import {
  MarkersModel,
  MinimalActionModel,
  TeyunaActionModel,
  TeyunaGeneralActionModel,
} from '../../../domain/models/actions.model';
import { TeyunaPresentationModel } from '../../../domain/models/presentation.model';
import { TeyunaSchemaDefinitionModel } from '../../../domain/models/schema-definition.model';

@Component({
  selector: 'tyn-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements AfterViewInit, OnChanges, OnDestroy {
  private combinedTriggers?: Observable<any>;

  static route = 'list';

  $combinedTriggersSubscription?: Subscription;
  $onDataSubscription?: Subscription;
  @ViewChild(AdDirective, { static: true }) adHost!: AdDirective;
  allItemsLength = 0;
  @Input() content!: TemplateRef<any>;
  /**
   * This is the action used for creating data to the model
   *
   * @type {TeyunaActionModel}
   * @memberof ListComponent
   */
  @Input('create-action') createAction: TeyunaActionModel | undefined;
  data: any;
  /**
   * This function lets to get the data
   *
   * @memberof ListComponent
   */
  @Input('data-function') dataFunction!: (
    page: number,
    sizePage: number,
    definition: any
  ) => Observable<any>;
  /**
   * Model that configures the columns, tags, definitions and schema to be used.
   *
   *  columns: [
   *     {
   *       definition: string;
   *       tag: string;
   *     }
   *   ];
   *   definitions: string[];
   *   tags: string[];
   *   schema: any;
   * @type {TeyunaSchemaDefinitionModel}
   * @memberof ListComponent
   */
  @Input() definition!: TeyunaSchemaDefinitionModel;
  /**
   * This is the action used for deleting data to the model
   *
   * @type {MinimalActionModel}
   * @memberof ListComponent
   */
  @Input('delete-action') deleteAction!: MinimalActionModel;
  /**
   *Actions executed for all data inside the view
   *
   * @type {TeyunaActionModel[]}
   * @memberof ListComponent
   */
  @Input('general-actions') generalActions!: TeyunaGeneralActionModel[];
  generatedMarkers?: {
    [key: string]: Observable<{
      display: string;
      backgroundColor?: string | undefined;
      color?: string | undefined;
      icon?: string | undefined;
    }>[];
  };

  generatedMarkersLength?: number;
  /**
   *Current page of the data
   *
   * By default, it's zero, and continuely will be changed when the user interact on the Graphical interface.
   * @memberof ListComponent
   */
  @Input() initialPage = 0;
  innerDefinitions: (string | number | symbol)[] | undefined;
  innerRowActions: TeyunaActionModel[];
  items: any;
  lastPage!: number;
  /**
   * Actions executed per row
   *
   * @type {((TeyunaActionModel | string)[])}
   * @memberof ListComponent
   */
  @Input() markers!: MarkersModel[];
  @Input('no-data-message') noDataMessage?: string;
  onChangePageConfiguration?: Subject<{
    page: number;
    sizePage: number;
  }>;
  onChangePageNumber?: BehaviorSubject<number>;
  onChangePageSize?: BehaviorSubject<number>;
  @Input('on-data') onData?: Observable<any>;
  @Output('on-request-data') onRequestData: EventEmitter<any>;
  page!: number;
  /**
   * Configure how to visualize the information on the screen.
   *
   * This structure is recursive, that means that inside the screen, the algorithm makes
   * a tree with the components provided by the library.
   *
   * @type {TeyunaPresentationModel}
   * @memberof ListComponent
   */
  @Input() presentation!: TeyunaPresentationModel;
  /**
   * Actions executed per row
   *
   * @type {((TeyunaActionModel)[])}
   * @memberof ListComponent
   */
  @Input('row-actions') rowActions!: TeyunaActionModel[];
  @Output('selectedItem') selectedItem = new EventEmitter();
  selectedItems: any;
  /**
   *Wheter show or not the paginator element
   *
   * @memberof ListComponent
   */
  @Input('show-paginator') showPaginator = true;
  /**
   *Size of the page
   *
   * @memberof ListComponent
   */
  @Input('size-page') sizePage = 10;
  subscriptions: Array<Subscription>;
  /**
   *Title of the page
   *
   * @memberof ListComponent
   */
  @Input() title?: string;
  @Input() triggers?: Observable<any>[];
  /**
   * This is the action used for updating data to the model
   *
   * @type {TeyunaActionModel}
   * @memberof ListComponent
   */
  @Input('update-action') updateAction!: MinimalActionModel;
  visualItems: any;

  constructor(
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.onRequestData = new EventEmitter();

    this.items = {};
    this.visualItems = {};
    this.selectedItems = {};
    this.innerRowActions = [];
    this.subscriptions = [];
  }

  addActions() {
    this.getDefinitions();

    if (this.generalActions && this.generalActions.length) {
      this.innerDefinitions?.unshift(' ');
    }
    this.innerDefinitions?.push('actions');

    this.innerRowActions = [...(this.rowActions || [])];

    if (this.deleteAction) {
      const alreadyExistDelete = this.innerRowActions.find(
        (a) => a.display == (this.deleteAction.display ?? 'Eliminar')
      );

      if (!alreadyExistDelete) {
        this.innerRowActions.unshift({
          ...this.deleteAction,
          display: this.deleteAction.display ?? 'Eliminar',
          icon: this.deleteAction.icon ?? 'clear',
        });
      }
    }

    if (this.updateAction) {
      const alreadyExistUpdate = this.innerRowActions.find(
        (a) => a.display == (this.updateAction.display ?? 'Editar')
      );

      if (!alreadyExistUpdate) {
        this.innerRowActions.unshift({
          ...this.updateAction,
          display: this.updateAction.display ?? 'Editar',
          icon: this.updateAction.icon ?? 'edit',
          mode: 'edit',
        });
      }
    }
  }

  isActionEnabled(
    action: TeyunaActionModel,
    element: any
  ): Observable<Boolean> {
    const enabled = action.enabled;

    if (enabled == undefined) {
      return of(true);
    }

    if (typeof enabled == 'boolean') {
      return of(enabled);
    }
    if (typeof enabled == 'function') {
      const enabledResponse = enabled(element);

      if (typeof enabledResponse == 'boolean') {
        return of(enabledResponse);
      }
      if (enabledResponse instanceof Promise) {
        return from(enabledResponse);
      }
      if (enabledResponse instanceof Observable) {
        return enabledResponse;
      }
      return of(true);
    }

    return of(true);
  }

  execute(action: TeyunaActionModel, obj: any) {
    const realItem = this.items[this.page].filter(
      (i: any) =>
        i[this.definition.schema.key] == obj[this.definition.schema.key]
    )[0];

    const exec = (item: any, items: any[]) => {
      if (typeof action.event === 'string') {
        eval(action.event);
      } else {
        action.event(item, items);
      }
    };

    let dialogRef;
    if (action) {
      if (action.mode) {
        dialogRef = this.goToDetail(action.mode, realItem);
      } else {
        exec(realItem, this.items[this.page]);
      }
    }
  }
  nanValidator(val: any) {
    if (!Number.isNaN(val)) {
      return val;
    }
    return '-';
  }
  executeMany(action: TeyunaActionModel): void {
    let formattedItems: any = {};

    for (const page in this.selectedItems) {
      if (!formattedItems[page]) {
        formattedItems[page] = [];
      }
      for (const element of this.selectedItems[page]) {
        const realItem = this.items[page].filter(
          (i: any) =>
            i[this.definition.schema.key] == element[this.definition.schema.key]
        )[0];

        formattedItems[page].push(realItem);
      }
    }

    if (typeof action.event === 'string') {
      eval(action.event);
    } else {
      action.event(formattedItems);
    }
  }

  generateMarkers() {
    var a: Observable<{
      display: string;
      backgroundColor?: string | undefined;
      color?: string | undefined;
      icon?: string | undefined;
    }>[] = [];

    if (!this.markers) {
      return;
    }
    this.generatedMarkers = {};
    for (const row of this.items[this.page]) {
      let temp: any = [];
      for (const marker of this.markers) {
        if (marker.enabled == undefined) {
          temp.push(marker.generate(row));
        } else {
          marker.enabled(row).subscribe((x) => {
            if (x) {
              temp.push(marker.generate(row));
            }
          });
        }
      }
      this.generatedMarkers[row[this.definition.schema.key]] = temp;
    }
    console.log('this.generatedMarkers, ', this.generatedMarkers);
  }

  getDefinitions() {
    this.innerDefinitions = [
      ...this.definition.columns.map((x) => x.definition),
    ];
  }

  goToDetail(mode: string, item: any) {
    const realItem = this.items[this.page].filter(
      (i: any) =>
        i[this.definition.schema.key] == item[this.definition.schema.key]
    );

    this.selectedItem.emit({
      item: realItem[0],
      mode: mode,
      columns: this.definition.columns,
      definitions: this.innerDefinitions,
      // tags: this.definition.tags,
      presentation: this.presentation,
    });
  }

  goToPage(diff: number) {
    if (this.page + diff >= 0) {
      this.page += diff;
    }
    if (this.page > this.lastPage) {
      this.lastPage = this.page;
    }

    this.onChangePageNumber?.next(this.page);
    this.onChangePageConfiguration?.next({
      page: this.page,
      sizePage: this.sizePage,
    });
    if (!(this.page in this.items) && this.dataFunction) {
      this.subscriptions.push(
        this.dataFunction(this.page, this.sizePage, this.definition)

          .pipe(
            mergeMap((currentItems: any[]) => {
              return this.normalizeData(currentItems);
            }),
            map((currentItems: any[]) => {
              return this.useFormatters(currentItems);
            })
          )

          .subscribe((data) => {
            this.items[this.page] = data.map((d) => d.original);
            this.visualItems[this.page] = data.map((d) => d.visual);

            this.selectedItems[this.page] = [];
            this.generateMarkers();

            this.allItemsLength = Object.values(this.items)
              .map((t: any) => t.length)
              .reduce((acc, item) => acc + item, 0);
            this.changeDetectorRef.detectChanges();
          })
      );
    }
  }

  insertNewItem(newItem: any) {
    this.items[this.page].unshift(newItem);
  }

  ngAfterViewInit(): void {
    this.goToPage(0);

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const condition =
      changes.definition !== undefined &&
      (changes.definition.currentValue !== undefined ||
        changes.definition.previousValue !== undefined);
    if (condition) {
      this.addActions();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      for (const s of this.subscriptions) {
        s.unsubscribe();
      }
    }

    this.$onDataSubscription?.unsubscribe();
    this.$combinedTriggersSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.data) {
      this.initialPage = this.data.initialPage;
      this.definition = this.data.definition;
      this.presentation = this.data.presentation;
      this.rowActions = this.data.rowActions;
      this.generalActions = this.data.generalActions;
      this.createAction = this.data.createAction;
      this.dataFunction = this.data.dataFunction;
      this.sizePage = this.data.sizePage;
      this.noDataMessage =
        this.data.noDataMessage || 'No hay información para mostrar';
      this.updateAction = this.data.updateAction;
      this.deleteAction = this.data.deleteAction;
      this.title = this.data.title;
      this.page = this.initialPage;
      this.lastPage = this.page;
      this.showPaginator = this.data.showPaginator;

      this.triggers = this.data.triggers;
      this.onData = this.data.onData;
      this.markers = this.data.markers;
      this.addActions();
    }

    this.onChangePageNumber = new BehaviorSubject(this.initialPage);
    this.onChangePageSize = new BehaviorSubject(this.sizePage);
    this.onChangePageConfiguration = new Subject();
    // this.onChangePageNumber.next(this.initialPage);
    this.onChangePageConfiguration.next({
      sizePage: this.sizePage,
      page: this.initialPage,
    });
    let t: Observable<any>[] = [this.onChangePageConfiguration];
    // let t: Observable<any>[] = [this.onChangePageNumber, this.onChangePageSize];

    if (this.triggers && this.triggers.length > 0) {
      t = [...t, ...this.triggers];
    }

    this.combinedTriggers = combineLatest(t);

    this.$combinedTriggersSubscription = this.combinedTriggers.subscribe(
      (data) => {
        const confPage = data[0];
        const rest = [...data];
        rest.splice(0, 1);

        this.onRequestData.emit([confPage.page, confPage.sizePage, ...rest]);
      }
    );

    this.$onDataSubscription = this.onData
      ?.pipe(
        filter((x) => x !== null),

        mergeMap((currentItems: any[]) => {
          return this.normalizeData(currentItems);
        }),
        map((currentItems: any[]) => {
          return this.useFormatters(currentItems);
        })
      )
      .subscribe((data: any[]) => {
        this.items[this.page] = data.map((d) => d.original);
        this.visualItems[this.page] = data.map((d) => d.visual);
        this.generateMarkers();

        this.selectedItems[this.page] = [];
        this.allItemsLength = Object.values(this.items)
          .map((t: any) => t.length)
          .reduce((acc, item) => acc + item, 0);

        this.changeDetectorRef.detectChanges();
      });
  }

  normalizeData(currentItems: any[]) {
    const relationships = this.definition.relationship;

    const response = currentItems.map((currentItem) => {
      const flatPropertyValues: Observable<{
        property: string;
        value: string | undefined;
      }>[] = relationships.map((r) => {
        return r.dataFunction().pipe(
          map((fullValues) => {
            const answer = fullValues.filter(
              (fullValue) => fullValue.value == currentItem[r.from]
            );

            return answer.length ? answer[0] : undefined;
          }),
          map((val) => {
            return {
              property: r.from,
              value: val ? val.display : '',
            };
          })
        );
      });

      return flatPropertyValues.length
        ? zip(...flatPropertyValues).pipe(
            map((comb) => {
              const visual = { ...currentItem };

              for (const c of comb) {
                visual[c.property] = c.value;
              }
              return {
                original: currentItem,
                visual,
              };
            })
          )
        : of({
            original: JSON.parse(JSON.stringify(currentItem)),
            visual: JSON.parse(JSON.stringify(currentItem)),
          });
    });

    return zip(...response).pipe(defaultIfEmpty([] as any[]));
  }

  pageIsValid() {
    return this.initialPage in this.items;
  }

  updateItem(event: any, data: any) {
    let index = 0;
    for (const item of this.selectedItems[this.page]) {
      if (JSON.stringify(item) === JSON.stringify(data)) {
        break;
      }
      index += 1;
    }
    if (event.target.checked) {
      this.selectedItems[this.page].push(data);
    } else {
      this.selectedItems[this.page].splice(index, 1);
    }
    this.selectedItems = { ...this.selectedItems };
  }

  useFormatters(
    data: { original: any; visual: any }[]
  ): { original: any; visual: any }[] {
    const columns = this.definition.columns.filter(
      (column) => !!column.formatter
    );

    return data.map((d) => {
      const visual = d.visual;

      for (const col of columns) {
        const changedValue = col.formatter!(visual[col.definition]);
        visual[col.definition] = changedValue;
        if (changedValue instanceof HTMLElement) {
          visual[col.definition] = this.sanitizer.bypassSecurityTrustHtml(
            changedValue.outerHTML
          );
        }
      }

      return {
        original: d.original,
        visual,
      };
    });
  }
}
