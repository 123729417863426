import { Inject, Injectable } from '@angular/core';
import { GeneralService } from 'src/app/core/general.service';
import { CommentEntity } from '../entities/comments';
import { FIRESTORE_TOKEN, STORAGE_TOKEN } from 'src/app/general.config';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { PostService } from './post.service';


@Injectable({
  providedIn: 'root'
})
export class CommentService extends GeneralService<CommentEntity>{
  static collection = "comments";

  static parentCollections = [
    PostService.collection
  ];
  
  constructor(
    @Inject(FIRESTORE_TOKEN)private dbInstance: Firestore,
    @Inject(STORAGE_TOKEN) private storageInstance: FirebaseStorage,
    
    ) { 
    super();
    this.init(dbInstance, storageInstance, CommentService.parentCollections, CommentService.collection);

  }
}
