<div class="col-11 col-md-8 col-xl-6 col-xxl-5 py-3">
  <form
    class="container w-100 h-100 d-flex align-items-center justify-content-center flex-column"
    [formGroup]="formGroup"
  >
    <img
      width="200px"
      src="assets/images/logo.png"
      class="m-3 mb-5"
      alt=""
    />

    <div class="my-2 w-100">
      <acl-input label="Correo electrónico" formControlName="email">
      </acl-input>
    </div>
    <div class="my-2 w-100">
      <acl-input label="Contraseña" type="password" formControlName="password">
      </acl-input>
    </div>

    <div class="d-flex my-2 justify-content-center col-12">
      <acl-button (click)="login()"> Ingresar </acl-button>
    </div>
  </form>
</div>
