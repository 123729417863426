import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ComponentsServiceComponent } from 'src/app/views/services/components-service/components-service.component';
import { LandingServiceComponent } from 'src/app/views/services/landing-service/landing-service.component';
import { ProductsSaasComponent } from 'src/app/views/services/products-saas/products-saas.component';
import { ServicesRoutingModule } from 'src/app/views/services/services.routing.module';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss'],
})
export class OurServicesComponent implements OnInit {
 
  services: {
    name: string;
    description: string;
    fromColor: string;
    toColor: string;
    image: string;
    path?: string;
  }[];
  constructor(private router: Router) {
    this.services = [
      {
        name: 'Diseño de Páginas Web',
        description: `Captura la atención de tus visitantes con una página web impactante destacando tus productos o servicios, asegurando una experiencia de usuario fluida y atractiva.`,
        fromColor: '#111',
        toColor: '#156',
        image: '../../../assets/images/services/landing-pages.webp',

        path: `${ServicesRoutingModule.route}/${LandingServiceComponent.route}`,

      },
      {
        name: 'Soluciones empresariales',
        description: `Impulsa el crecimiento de tu empresa con soluciones tecnológicas diseñadas para optimizar tus procesos, mejorando la eficiencia y automatizando tareas.`,
        fromColor: '#321',
        toColor: '#113',
        image: '../../../assets/images/services/software-development.webp',
      },
      {
        name: 'Desarrollo de apps Móviles',
        description: `Lleva tu negocio a la palma de la mano de tus clientes con una app móvil intuitiva, eficiente, personalizada y diseñada a la medida. `,
        fromColor: '#222',
        toColor: '#235',
        image:
          '../../../assets/images/services/mobile-software-development.webp',
      },
      {
        name: 'Automatización e integración',
        description: `Optimiza tus operaciones con soluciones de automatización e integración que conectan tus sistemas y flujos de trabajo reduciendo costos.`,
        fromColor: '#224',
        toColor: '#354',
        image: '../../../assets/images/services/process-automation.webp',
      },
      {
        name: 'Asesoramiento en Software',
        description: `Toma decisiones informadas con nuestro servicio de asesoramiento en software. Te guiamos en la selección, implementación y optimización de las soluciones tecnológicas más adecuadas para tu negocio.`,
        fromColor: '#226',
        toColor: '#267',
        image: '../../../assets/images/services/advice.webp',
      },
      {

        name: 'Componentes / Librerías',
        description: `Utiliza nuestras librerías en tus desarrollos. Contamos con librerías para manejo de georeferenciación, arquitectura de software, logs, scaffolding y tratamiento de imágenes.`,
        fromColor: '#113',
        toColor: '#263',
        image: '../../../assets/images/services/components.webp',
        path: `${ServicesRoutingModule.route}/${ComponentsServiceComponent.route}`,
        
      },
      {
        name: 'Productos SaaS',
        description: `Si tienes una empresa de servicios a domicilio u organización de torneos deportivos, tenemos las soluciones que cumplen con dichos requerimientos.`,
        fromColor: '#113',
        toColor: '#643',
        image: '../../../assets/images/services/products.webp',
        path: `${ServicesRoutingModule.route}/${ProductsSaasComponent.route}`,

      },
    ];
  }

  ngOnInit(): void {}
}
