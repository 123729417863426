import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsModalComponent } from './errors-modal/errors-modal.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

const COMPONENTS = [ErrorsModalComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule, MatListModule, MatIconModule],
})
export class ComponentsModule {}
