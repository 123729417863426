import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AnaliticsService } from 'src/app/services/analitics.service';
import { SEOConfig, SeoService } from 'src/app/services/seo.service';
import { ContactUsComponent } from '../../contact-us/contact-us.component';
import { SERVICE_MODULE_ROUTE } from '../config';

export interface Advantage {
  name: string;
  description: string;
  image: { path: string };
}
export interface Situation {
  name: string;
  description: string[];
  image: { path: string; alt: string };
  aa: string;
}
@Component({
  selector: 'app-landing-service',
  templateUrl: './landing-service.component.html',
  styleUrls: ['./landing-service.component.scss'],
})
export class LandingServiceComponent implements OnInit {
  static route = 'paginas-web-empresariales';

  static config: SEOConfig = {
    title: 'Sitios Web Empresariales',
    canonicalUrl: [SERVICE_MODULE_ROUTE, LandingServiceComponent.route].join(
      '/'
    ),
  };

  currentAdvantage?: Advantage;
  advantages: Advantage[];
  situations: Situation[];
  dockItems: MenuItem[];
  selectedExtensions: any;
  selectedPlan: any;

  constructor(
    private analiticsService: AnaliticsService,
    private router: Router,
    private seo: SeoService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.advantages = [
      {
        name: 'Analítica',
        image: {
          path: 'assets/views/landing-service/annual-report.json',
        },
        description:
          'Obtén un informe relacionado con la cantidad de usuarios que interactúan con tu sitio, además de los lugares de procedencia, las partes de la página más frecuentadas y más.',
      },
      {
        name: 'Fronteras Abiertas',
        image: {
          path: 'assets/views/landing-service/airplane.json',
        },
        description:
          'Tu sitio web estará disponible para cualquier persona en el mundo, sin limitaciones geográficas ni horarias, permitiendo que tu negocio esté siempre accesible.',
      },
      {
        name: 'Despliegues automáticos',
        image: {
          path: 'assets/views/landing-service/rocket.json',
        },
        description:
          'Implementa y actualiza tu página de manera automatizada, reduciendo el tiempo de espera y asegurando que siempre esté a la vanguardia.',
      },
      {
        name: 'Tu propio código',
        image: {
          path: 'assets/views/landing-service/coding.json',
        },
        description:
          'Tendrás control total sobre el código de tu sitio, lo que te permitirá hacer modificaciones y adaptaciones de acuerdo con las necesidades de tu negocio.',
      },
      {
        name: 'Personalización',
        image: {
          path: 'assets/views/landing-service/monitor.json',
        },
        description:
          'Tu sitio web puede ser personalizado completamente para que refleje la identidad de tu marca y ofrezca una experiencia única a tus usuarios.',
      },
      {
        name: 'Diseño adaptable',
        image: {
          path: 'assets/views/landing-service/responsive.json',
        },
        description:
          'Tu sitio se verá y funcionará correctamente en cualquier dispositivo, ya sea un ordenador, tablet o teléfono móvil, proporcionando una experiencia fluida a todos tus usuarios.',
      },
      {
        name: 'Rápida entrega',
        image: {
          path: 'assets/views/landing-service/clock.json',
        },
        description:
          'Obtén tu sitio web en poco tiempo gracias a un proceso de desarrollo optimizado, reduciendo la espera para comenzar a utilizar tu sitio.',
      },
      {
        name: 'Conexión con redes sociales',
        image: {
          path: 'assets/views/landing-service/social-media.json',
        },
        description:
          'Integra tu sitio web con tus perfiles de redes sociales para aumentar tu alcance y facilitar la interacción con tus seguidores.',
      },
      {
        name: 'Botón de pago',
        image: {
          path: 'assets/views/landing-service/money.json',
        },
        description:
          'Agrega un botón de pago directamente en tu sitio para ofrecer a tus clientes una forma rápida y segura de realizar transacciones.',
      },
    ];

    this.situations = [
      {
        name: 'No eres el dueño de tu código',
        description: [
          'Te comunicas repetidamente con tu proveedor de tecnología y no te responde.',
          'Quieres hacer modificaciones a tu sitio y no hay respuesta.',
          'Te es imposible obtener tu sitio para poder contratar las modificaciones pertinentes.',
        ],
        image: {
          path: 'assets/views/landing-service/no-answerd-chat.webp',
          alt: 'proveedor de tecnologia no contesta',
        },
        aa: 'Significa que no eres el propietario de tus activos tecnológicos',
      },
      {
        name: 'No conoces el tráfico hacia tu sitio',
        description: [
          'No sabes cuántos usuarios nuevos llegan a tu sitio',
          'Es desconocida cuál página es la más visitada',
          'Ni idea sobre desde qué dispositivos te consultan tus clientes',
        ],
        image: {
          path: 'assets/views/landing-service/no-analitics.webp',
          alt: 'no hay analitica de usuarios',
        },
        aa: 'Indica que no eres consciente de tus clientes',
      },
    ];
    this.dockItems = [
      {
        label: 'Finder',
        icon: 'assets/github.png',
      },
      {
        label: 'App Store',
        icon: 'assets/showcase/images/dock/appstore.svg',
      },
      {
        label: 'Photos',
        icon: 'assets/showcase/images/dock/photos.svg',
      },
      {
        label: 'Trash',
        icon: 'assets/showcase/images/dock/trash.png',
      },
    ];
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (rect.top <= windowHeight && rect.bottom >= 0) {
      this.renderer.addClass(this.el.nativeElement, 'animate__animated');
      this.renderer.addClass(this.el.nativeElement, 'animate__fadeIn');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'animate__animated');
      this.renderer.removeClass(this.el.nativeElement, 'animate__fadeIn');
    }
  }
  handler(item: any) {
    this.currentAdvantage = item;
    const myModal = document.getElementById('exampleModal');
  }
  goToContact() {
    this.router.navigate([ContactUsComponent.route], {
      queryParams: {
        selectedData: JSON.stringify({
          selectedPlan: this.selectedPlan,
          selectedExtensions: this.selectedExtensions,
        }),
      },
    });
  }

  ngOnInit(): void {
    this.analiticsService.log('web_site_service_visit');
    this.seo.setUp(LandingServiceComponent.config);
  }

  onSelectedPlan(event: any) {
    console.log(event);
    this.selectedPlan = event;
  }
  onSelectedExtensions(event: any) {
    console.log(event);
    this.selectedExtensions = event;
  }
}
