import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.scss'],
})
export class ExtensionsComponent {
  selectedExtensions: any[] = [];
  @Output('on-selected-extensions') onSelectedExtensions: EventEmitter<any[]>;

  extensions = [
    {
      display: 'Analítica',
      img: '../../../../../assets/views/landing-service/analitics.png',
      description:
        'Obtén un informe relacionado con la cantidad de usuarios que interactúan con tu sitio, además de los lugares de procedencia, las partes de la página más frecuentadas y más.',
      commingSoon: false,
    },
    {
      display: 'Protección contra bots',
      img: '../../../../../assets/views/landing-service/recaptcha.png',
      description:
        'Protege tus formularios contra el ataque de bots. Recibe solo los mensajes de las personas que realmente quieren hacer parte de tu negocio y no de robots que generan SPAM.',
      commingSoon: false,
    },
    {
      display: 'Comprobante IA',
      img: '../../../../../assets/views/landing-service/bank.jpg',
      description:
        'Podrás validar pagos a tu negocio de manera automática sin necesidad de implementar una pasarela de pago.',
      commingSoon: true,
    },
    {
      display: 'Link Building Integrado',
      img: '../../../../../assets/views/landing-service/link-building.png',
      description:
        'Une tu empresa a nuestra red de SEO. Tu empresa mejorará orgánicamente su posicionamiento al ser referenciada por otras dentro del mismo nicho.',
      commingSoon: true,
    },
    {
      display: 'Sakura IA',
      img: '../../../../../assets/views/landing-service/sakura.png',
      description:
        'Usa tu blog para generar contenido en tus redes sociales usando nuestro generador de IA.',
      commingSoon: true,
    },
  ];
  onChangeCheckbox($event: any, extension: any) {
    if ($event.target.checked) {
      this.selectedExtensions.push(extension);
    } else {
      this.selectedExtensions = this.selectedExtensions.filter(
        (x) => x.display != extension.display
      );
    }
    this.onSelectedExtensions.emit(this.selectedExtensions);
  }
  constructor() {
    this.onSelectedExtensions = new EventEmitter();
  }
}
