import {
  TeyunaContainerConfiguration,
  TeyunaPresentationModel
} from '../../domain/models/presentation.model';

export function defaultCol(
  field: TeyunaPresentationModel
): TeyunaPresentationModel {
  return {
    component: 'col',
    spacing: {
      margin: '10px 0',
    },
    configuration: {},
    responsive: {
      xs: {
        layout: 12,
      },
      sm: {
        layout: 12,
      },
      md: {
        layout: 6,
      },
      lg: {
        layout: 5,
      },
      xl: {
        layout: 4,
      },
    },
    children: [field],
  };
}
export function autoplace(
  fields:
    | TeyunaPresentationModel[]
    | {
        [index: string]: TeyunaPresentationModel[];
      }
): TeyunaPresentationModel {
  const container: TeyunaContainerConfiguration = {
    component: 'container',
    children: [],
  };
  const row: TeyunaPresentationModel = {
    component: 'row',

    configuration: {
      mainAlign: 'center',
      crossAlign: 'center',
    },
    spacing: {
      margin: '30px 0',
    },
    children: [],
  };
  if (Array.isArray(fields)) {
    for (const field of fields) {
      row.children.push(defaultCol(field));
    }
  } else {
    const card: TeyunaPresentationModel = {
      children: [],
      component: 'card',
    };
    for (const title in fields) {
      container.children.push(card);
      if (Object.prototype.hasOwnProperty.call(fields, title)) {
        const elements = fields[title];

        for (const element of elements) {
          card.children.push(defaultCol(element));
        }
      }
    }
    row.children.push(card);
  }
  container.children.push(row);
  return container;
}
