import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { haveToBeDefined } from './validators';
export type DirectValidatorType =
  | 'required'
  | 'email'
  | 'requiredTrue'
  | 'haveToBeDefined';
export type IndirectValidatorType =
  | 'max'
  | 'min'
  | 'pattern'
  | 'minLength'
  | 'maxLength';
export type ValidatorType = DirectValidatorType | IndirectValidatorType;
export type ErrorFromValidatorType =
  | 'required'
  | 'email'
  | 'requiredTrue'
  | 'haveToBeDefined'
  | 'max'
  | 'min'
  | 'pattern'
  | 'minlength'
  | 'maxlength';

export type TeyunaDirectValidator = {
  [index in DirectValidatorType]: (...args: any[]) => ValidationErrors | null;
};
export type TeyunaIndirectValidator = {
  [index in IndirectValidatorType]: (...args: any[]) => ValidatorFn;
};

export const ValidatorsMapper: TeyunaDirectValidator | TeyunaIndirectValidator =
  {
    required: Validators.required,
    email: Validators.email,
    max: Validators.max,
    min: Validators.min,
    pattern: Validators.pattern,
    requiredTrue: Validators.requiredTrue,
    haveToBeDefined,
    minLength: Validators.minLength,
    maxLength: Validators.maxLength,
  };
