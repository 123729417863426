import { Inject, Injectable } from '@angular/core';
import { Analytics, logEvent } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { ANALITICS_TOKEN } from '../general.config';

@Injectable()
export class AnaliticsService {
  constructor(@Inject(ANALITICS_TOKEN) private analitics: Analytics) {}
  log(eventName: string, params = {}) {
    const user_agent = navigator.userAgent;

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const device_kind = /Mobi|Android/i.test(user_agent) ? 'mobile' : 'desktop';

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let os = 'undefined';

    if (user_agent.indexOf('Windows') !== -1) os = 'windows';
    else if (user_agent.indexOf('Mac') !== -1) os = 'macOS';
    else if (user_agent.indexOf('Linux') !== -1) os = 'linux';
    else if (/Android/.test(user_agent)) os = 'android';
    else if (/iPhone|iPad|iPod/.test(user_agent)) os = 'iOS';

    const currentDate = new Date();

    const date = currentDate.toISOString();

    const payload = {
      user_agent,
      size: screenWidth + 'x' + screenHeight,
      device_kind,
      timezone,
      os,
      date,
      ...params,
    };
    if (environment.production) {
      logEvent(this.analitics, eventName, payload);
    } else {
      console.log(eventName, payload);
    }
  }
}
