import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseApp } from 'firebase/app';
import { Auth, signInWithEmailAndPassword } from 'firebase/auth';
import { Subscription } from 'rxjs';
import {
  AUTH_TOKEN,
  EMAIL_SESSION_KEY,
  FIREBASE_TOKEN,
  TOKEN_SESSION_KEY,
} from 'src/app/general.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  static route = 'login';
  formGroup: UntypedFormGroup;

  loginSubscription!: Subscription;
  messages = {
    'Empty Credentials': 'Algunas de las credenciales están vacías',
    'Wrong credentials': 'Las credenciales son incorrectas.',
  };

  haveToBeDefined(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const response =
      value !== undefined && value !== null && value != '' && value.length >= 5;
    return !response ? { required: true } : null;
  }

  constructor(
    private router: Router,
    @Inject(AUTH_TOKEN) private auth: Auth,
    @Inject(FIREBASE_TOKEN) private app: FirebaseApp
  ) {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [this.haveToBeDefined]),
    });
  }
  ngOnInit(): void {
    console.log('kafjsdkl');
    
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

  login() {
    if (this.formGroup.valid) {
      const value = this.formGroup.value;
      const data = {
        password: value.password,
        username: value.email,
      };

      signInWithEmailAndPassword(this.auth, value.email, value.password).then(
        (RES) => {
          console.log(RES);
          RES.user.getIdToken().then((x) => {
            sessionStorage.setItem(TOKEN_SESSION_KEY, x);
            const mail = RES.user.email;
            if (mail) {
              sessionStorage.setItem(EMAIL_SESSION_KEY, mail);
              this.router.navigate(['/']);
            }
            console.log(x);
          });
        }
      );
    }
  }
}
