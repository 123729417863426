import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { filter, map } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EMAIL_SESSION_KEY, TOKEN_SESSION_KEY } from 'src/app/general.config';
import { Post } from '../../entities/post';
import { PostService } from '../../services/post.service';
import { addPrefix } from '../helpers';
import { PostGeneratorComponent } from '../post-generator/post-generator.component';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit, OnDestroy {
  static route = 'blog';

  $posts?: Observable<Post[] | null>;
  deleteSubscription?: Subscription;
  user?: { email: string | null; token: string | null };

  constructor(
    private router: Router,
    private postService: PostService,
    private dialog: MatDialog
  ) {}

  deletePost(post: Post) {
    const ref = this.dialog.open(ModalComponent, {
      data: {
        title: '¿Está seguro de eliminar?',
        kind: 'text',
        text: 'No se podrá recuperar la información',
        actions: [
          {
            display: 'Eliminar',
            handler: () => {
              const img = post.img;

              this.postService.deleteFile(img).then(() => {
                this.deleteSubscription = this.postService
                  .delete([], post.id)
                  .subscribe(() => {
                    this.getPost();
                  });
              });
            },
          },
        ],
      },
    });
  }

  getPost() {
    this.$posts = this.postService.getAll([]).pipe(
      filter((y) => !!y),
      map((posts) => {
        return posts!.map((post) => {
          return { ...post, img: addPrefix(post.img) };
        });
      }),
      // map(x=>{

      //   return [...x!,...x!]
      // })
    );
  }

  goTo() {
    this.router.navigate([PostGeneratorComponent.route]);
  }

  goToEditing(postId: string) {
    this.router.navigate([PostGeneratorComponent.route], {
      queryParams: {
        id: postId,
      },
    });
  }

  ngOnDestroy(): void {
    this.deleteSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    const email = sessionStorage.getItem(EMAIL_SESSION_KEY);
    const token = sessionStorage.getItem(TOKEN_SESSION_KEY);
    if (email && token) {
      this.user = { email, token };
    }
    this.getPost();
  }
}
