<header class="d-flex" *ngIf="displayRouting" >
  <button (click)="back()" mat-button color="accent" class="">
    <mat-icon> arrow_back </mat-icon>
  </button>
  <h2 *ngIf="mode != 'new'" class="col">{{ repr }} </h2>
  <h2 *ngIf="mode == 'new'" class="col"> Crear Nuevo </h2>
</header>
<form [formGroup]="formGroup">
  <ng-template aclAd></ng-template>
</form>

<div class="row tyn-footer justify-content-center d-flex m-0">
  <acl-button
    color="notification"
    size="xsmall"
    class="col-lg-2 col-6 d-flex justify-content-center"
    *ngIf="mode != 'detail'"
    (click)="saveItem()"
  >
    Confirmar
  </acl-button>
</div>
