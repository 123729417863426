<div class="px-2 col-11 col-sm-10 col-md-10 col-lg-8 col-xl-7 m-auto">
  <section class="aspect" *ngFor="let aspect of aspects; let i = index">
    <ng-container
      *ngIf="i % 2 == 0"
      [ngTemplateOutlet]="figure"
      [ngTemplateOutletContext]="{
        url: aspect.img
      }"
    ></ng-container>
    <section>
      <h3>{{ aspect.label }}</h3>
      <p>
        {{ aspect.description }}
      </p>

      <p-accordion>
        <p-accordionTab *ngFor="let faq of aspect.faqs" [header]="faq.question">
          <p>{{ faq.answer }}</p>
        </p-accordionTab>
      </p-accordion>
    </section>
    <ng-container
      *ngIf="i % 2 != 0"
      [ngTemplateOutlet]="figure"
      [ngTemplateOutletContext]="{
        url: aspect.img
      }"
    ></ng-container>
  </section>
</div>

<ng-template #figure let-url="url">
  <figure>
    <img [src]="url" alt="" />
    <!-- <img src="../../../../../assets/views/landing-service/code-repo.jpg" alt="" /> -->
  </figure>
</ng-template>
