import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { TeyunaConfigurations } from './interfaces';
import { PagesModule } from './presentation/pages/pages.module';
import {
  MANDATORY_VISUAL_COMPONENTS_TOKEN
} from './teyuna-injection-tokens';

@NgModule({
  declarations: [],
  imports: [PagesModule, CoreModule, CommonModule],
  exports: [PagesModule],
  providers: [],
})
export class TeyunaModule {
  static forRoot(config: TeyunaConfigurations): ModuleWithProviders<TeyunaModule> {
    return {
      ngModule: TeyunaModule,

      providers: [
        // { provide: INITIAL_PAGE_TOKEN, useValue: config.initialPage || 0 },
        // { provide: DEFINITION_TOKEN, useValue: config.definition },
        // { provide: PRESENTATION_TOKEN, useValue: config.presentation },
        // { provide: ROW_ACTIONS_TOKEN, useValue: config.rowActions },
        // { provide: GENERAL_ACTIONS_TOKEN, useValue: config.generalActions },
        // { provide: CREATE_ACTION_TOKEN, useValue: config.createAction },
        // {
        //   provide: DATA_FUNCTION_ACTION_TOKEN,
        //   useValue: config.dataFunctionAction,
        // },
        {
          provide: MANDATORY_VISUAL_COMPONENTS_TOKEN,
          useValue: config.visualComponents,
        },
        // { provide: SIZE_PAGE_TOKEN, useValue: config.sizePage || 10 },
      ],
    };
  }
}
