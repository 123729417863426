<h3 *ngIf="title" class="subtitlePost">{{ title }}</h3>
<h4 *ngIf="subtitle" class="subtitlePost">{{ subtitle }}</h4>

<p *ngIf="mainText" class="mainTextPost">
  {{ mainText }}
</p>

<ul *ngIf="list && list.length" class="listPost">
  <li *ngFor="let item of list">
    {{ item }}
  </li>
</ul>
