<label>{{ label }}</label>
<div class="switch-container">
  <input
    [checked]="value"
    [disabled]="disabled"
    #input
    type="checkbox"
    [id]="'switch-' + id"
  />
  <label
    [ngStyle]="{
      'background-color':  value ? backgroundColor ?? 'var(--accent-color)' : 'grey'
    }"
    [for]="'switch-' + id"
    >Toggle</label
  >
</div>
