import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogRoutingModule } from './features/blog/blog-routing.module';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { ServicesRoutingModule } from './views/services/services.routing.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: ContactUsComponent.route,
    component: ContactUsComponent,
  },
  {
    path: LoginComponent.route,
    component: LoginComponent,
  },
  {
    path: ServicesRoutingModule.route,
    loadChildren: () =>
      import('./views/services/services.module').then((x) => x.ServicesModule),
  },
  {
    path: BlogRoutingModule.route,
    loadChildren: () =>
      import('./features/blog/blog.module').then((x) => x.BlogModule),
  },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
