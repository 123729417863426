import { InjectionToken } from '@angular/core';



export const EMAIL_SESSION_KEY = 'mws-email';
export const TOKEN_SESSION_KEY = 'mws-token';


export const USER_INFORMATION_IT = new InjectionToken('Injection Token for user information');
export const FIRESTORE_TOKEN = new InjectionToken<string>('firestore');
export const STORAGE_TOKEN = new InjectionToken<string>('storagetoken');

export const FIREBASE_TOKEN = new InjectionToken<string>('firebase');
export const ANALITICS_TOKEN = new InjectionToken<string>('analitics');
export const AUTH_TOKEN = new InjectionToken<string>('auth');

export const RESOURCES_PERMISSIONS_IT = new InjectionToken(
  'Injection Token for resources permissions'
);
