<div *ngFor="let plan of plansData.plans; let i = index" class="support-card">
  <header>
    <div class="checkbox">
      <input type="radio" (change)="onChangeCheckbox($event,i)" name="extension" id="" />
    </div>
    <h3>{{ plan.name }}</h3>
    <p class="m-0">{{ plan.description }}</p>
  </header>
  <section>
    <table>
      <tbody>
        <tr>
          <td>Modificaciones anuales</td>
        </tr>
        <tr>
          <td>
            {{ plan.features.annualModifications }}
          </td>
        </tr>
        <tr>
          <td>Valor por hora y modificación</td>
        </tr>
        <tr>
          <td>
            {{ plan.features.additionalModificationsPerYear }}
          </td>
        </tr>
        <tr>
          <td>Precio anual</td>
        </tr>
        <tr>
          <td>
            {{ plan.features.price }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</div>
