import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlogRoutingModule } from './blog-routing.module';
import { ComponentsModule } from './components/components.module';
import { ViewsModule } from './views/views.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ViewsModule,
    BlogRoutingModule,
    ComponentsModule
  
  ]
})
export class BlogModule { }
