<div
  class="d-flex flex-column justify-content-center align-items-center h-100 w-100"
  *ngIf="kind == 'loading'"
>
  <h3
    *ngIf="title"
    [class]="'text-center text-uppercase py-2 px-2 title ' + status"
  >
    {{ title }}
  </h3>
  <div class="p-5">
    <app-default-loading> </app-default-loading>
  </div>
  <p class="py-3 px-2 my-3 text-center" [innerHtml]="text"></p>
</div>

<div class="d-flex flex-column pb-3" *ngIf="kind == 'text'">
  <h3 [class]="'text-center text-uppercase py-2 px-2 title ' + status">
    {{ title }}
  </h3>
  <p class="py-3 px-2 my-3 text-center" [innerHtml]="text"></p>
</div>

<footer class="d-flex mt-2" *ngIf="actions && actions.length > 0">
  <button
    *ngFor="let action of actions"
    class="col"
    [style.background]="action.background"
    [style.color]="action.color"
    [style.border]="'1px solid ' + action.borderColor"
    (click)="execute(action.handler)"
  >
    {{ action.display }}
  </button>
</footer>
