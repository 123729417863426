<header class="px-3">
  <h1 class="text-center text-uppercase py-4 title">
    Errores en el formulario
  </h1>
</header>
<section class="px-5 mt-5">
  <p class="text-center">
    Revisa estos errores y corrígelos para que puedas seguir con el proceso
  </p>
</section>
<mat-list role="list" class="container">
  <mat-list-item role="listitem" *ngFor="let error of normalizedErrors">
    <mat-icon
      [ngClass]="{
        pointer: !!error.target
      }"
      (click)="goTo(error.target)"
      matListItemIcon
      >chevron_right</mat-icon
    >
    <h4 matListItemTitle class="fw-bold py-1">{{ error.display }}</h4>
    <div matListItemLine class="d-flex flex-column">
      <span class="my-1" *ngFor="let e of error.errors"> {{ e }}</span>
    </div>
  </mat-list-item>
</mat-list>
