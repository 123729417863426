<div>
  <section *ngFor="let extension of extensions; let i = index">
    <div class="checkbox">
      <input
        type="checkbox"
        (change)="onChangeCheckbox($event, extension)"
        name="extension"
        id=""
      />
    </div>
    <div class="mb-2">
      <figure><img [src]="extension.img" alt="" /></figure>
      <header class="text-center">
        <h3>
          <strong>
            {{ extension.display }}
          </strong>
        </h3>
      </header>
    </div>
    <p class="text-justify">{{ extension.description }}</p>
    <footer *ngIf="extension.commingSoon">
      <span> Próximamente </span>
    </footer>
  </section>
</div>
