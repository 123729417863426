<section>
  <span class="material-icons" *ngIf="icon">{{ icon }}</span>

  <section class="combo-wrap">
    <label for="cpf-id3">{{ label }}</label>
    <input
      readonly
      [attr.color]="color"
      [attr.status]="status"
      [placeholder]="placeholder || ''"
      type="text"
      id="jokes"
      role="combobox"
      aria-owns="joketypes"
      aria-autocomplete="list"
      aria-expanded="true"
      data-active-option="item1"
      aria-activedescendant="item1"
      [disabled]="(formControl && formControl.disabled) || disabled"
      
    />
  </section>

  <span
    aria-expanded="true"
    aria-owns="joketypes"
    data-trigger="multiselect"
    class="material-icons"
    >expand_more</span
  >
</section>
<ul id="joketypes" role="listbox">
  <ng-container *ngIf="items">
    <li
      *ngFor="let item of items"
      [attr.item]="item | json"
      class="active"
      role="option"
      id="item1"
    >
      <span [attr.item]="item | json">
        {{ item.display }}
      </span>
      <!-- <acl-dropdown-item [color]="color" [attr.item]="item | json" [item]="item"> </acl-dropdown-item> -->
    </li>
  </ng-container>
</ul>
<span *ngIf="hint" class="text-base">{{ hint }}</span>

