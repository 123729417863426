<div *ngIf="post">
  <figure class="col-12 m-auto">
    <img [src]="post.img" alt="Imagen del post" class="w-100 rounded" />
  </figure>
  <section class="col-12 col-sm-11 col-xl-10 col-xx-8 m-auto">
    <header class="text-center py-5">
      <h1>
        <strong>
          {{ post.title }}
        </strong>
      </h1>
      <h2>
        <strong>
          {{ post.subtitle }}
        </strong>
      </h2>
    </header>
    <div class="px-3 px-sm-5">
      <ng-template #container> </ng-template>
    </div>
  </section>

  <section class="mt-5 col-12 col-sm-11 col-xl-10 col-xx-8 m-auto">
    <form class="d-flex flex-wrap" [formGroup]="formGroup">
      <div class="col-6 p-2">
        <mat-form-field color="accent" class="col-12" appearance="outline">
          <mat-label>Nombre:</mat-label>
          <input matInput placeholder="Tu nombre" formControlName="name" />
        </mat-form-field>
      </div>
      <div class="col-6 p-2">
        <mat-form-field class="col-12" color="accent" appearance="outline">
          <mat-label>Correo Eléctronico</mat-label>
          <input placeholder="Tu nombre" matInput formControlName="email" />
        </mat-form-field>
      </div>
      <div class="col-6 p-2">
        <mat-form-field appearance="outline" color="accent" class="col-12">
          <textarea
            rows="3"
            placeholder="Escribe un comentario..."
            matInput
            formControlName="message"
          >
          </textarea>
        </mat-form-field>
      </div>
      <div class="col-12 p-2">
        <button
          mat-raised-button
          color="accent"
          [disabled]="!formGroup.valid"
          (click)="sendMessage()"
        >
          Publicar Comentario
        </button>
      </div>
    </form>
    <header *ngIf="comments && comments.length" class="p-2">
      <h3>
        <strong> Comentarios </strong>
      </h3>
    </header>
    <ul>
      <li class="d-flex my-3" *ngFor="let data of comments">
        <img
          matListItemIcon
          src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
          width="50"
          height="50"
          class="rounded-circle me-3"
          alt="Usuario"
        />
        <div matListItemTitle class="d-flex flex-column">
          <strong>{{ data.name }}</strong>
          <p>{{ data.message }}</p>
          <strong>{{ data.date | date }}</strong>
        </div>
      </li>
    </ul>
  </section>
</div>
