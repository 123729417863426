import { Inject, inject, Injectable } from '@angular/core';
import { GeneralService } from 'src/app/core/general.service';
import { Post } from '../entities/post';
import { Firestore } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { FIRESTORE_TOKEN, STORAGE_TOKEN } from 'src/app/general.config';

@Injectable({
  providedIn: 'root'
})
export class PostService extends GeneralService<Post> {

  static collection = "post";
  static parentCollections = [];

  constructor(
    @Inject(FIRESTORE_TOKEN)private dbInstance: Firestore,
    @Inject(STORAGE_TOKEN) private storageInstance: FirebaseStorage,
  ) {
    super();
    this.init(dbInstance, storageInstance, PostService.parentCollections, PostService.collection);
  }

    


}
