<header class="p-2 pt-2 pt-sm-2 mt-2 mt-sm-2">
  <h1 class="my-5 text-center py-3">
    <strong>
      Adquiere tu propio <span class="highlight"> Sitio Web Empresarial </span>
    </strong>
  </h1>
</header>

<section class="d-flex flex-wrap align-items-center justify-content-center">
  <div
    class="col-12 col-sm-10 col-md-5 col-lg-5 col-xl-6 col-xxl-5 d-flex justify-content-center"
  >
    <figure class="col-9 col-sm-8 col-md-10 col-lg-10 col-xl-10">
      <img
        class="w-100"
        src="assets/views/landing-service/web.webp"
        alt="desarrollo de software"
      />
    </figure>
  </div>
  <div
    class="col-12 col-sm-11 col-md-7 col-lg-7 col-xl-6 col-xxl-7 pb-3 px-2 px-sm-3 text-center"
  >
    <h2 class="text-center my-3 my-md-2 px-2 px-sm-4 px-md-5">
      Todos tus
      <strong class="txt-primary"> clientes </strong>
      van a querer saber más de ti y de lo que

      <strong class="txt-primary"> haces. </strong>
    </h2>
    <p class="fs-6 fs-md-5 fs-lg-4">
      Un <strong class="highlight">Sitio Web Empresarial</strong> permitirá a
      tus clientes conocer mucho más acerca de la misión de la empresa, de
      quiénes la conforman, sus objetivos, sus valores y sus servicios.
    </p>
    <p class="fs-6 fs-md-5 fs-lg-4">
      Recuerda, el propósito del mismo es dar a tus clientes la información
      necesaría relacionada con el actuar de tu organización.
    </p>
  </div>
</section>

<section class="advantages mt-3 d-flex flex-column align-items-center">
  <section
    class="col-12 col-sm-11 col-xl-10 d-flex justify-content-center align-items-center flex-wrap"
  >
    <div
      class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 p-2 p-md-3"
      *ngFor="let item of advantages"
    >
      <div
        class="advantage"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        (click)="handler(item)"
      >
        <figure>
          <ng-lottie class="w-100" [options]="item.image"> </ng-lottie>
        </figure>
        <strong class="text-center">
          {{ item.name }}
        </strong>
      </div>
    </div>
  </section>
</section>
<p-divider></p-divider>

<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4">
  <header class="my-5">
    <h2 class="text-center">
      <strong>
        ¿Te identificas con alguna de las siguientes situaciones?
      </strong>
    </h2>
    <h3 class="text-center"></h3>
  </header>
  <div
    *ngFor="let situation of situations"
    class="situation col-12 col-sm-10 col-xl-8 col-xxl-8"
  >
    <header class="col-12">
      <h4 class="m-0">{{ situation.name }}</h4>
    </header>
    <div
      class="col-12 col-md-7 col-xxl-8 px-3 px-md-5 py-3 px-md-2 d-flex flex-column justify-content-center"
    >
      <p *ngFor="let d of situation.description">
        {{ d }}
      </p>
    </div>
    <figure
      class="col-12 col-md-5 col-xxl-4 m-0 d-flex justify-content-center align-items-end"
    >
      <img
        class="col-8 col-sm-6 col-md-8"
        [src]="situation.image.path"
        [attr.alt]="situation.image.alt"
        srcset=""
      />
    </figure>
    <footer class="col-12">
      {{ situation.aa }}
    </footer>
  </div>
</section>

<section
  class="benefits col-11 col-sm-11 col-lg-10 col-xl-11 ps-2 px-md-3 px-lg-4"
>
  <header class="my-5">
    <h2 class="text-center">
      <strong> Queremos que nuestro servicio sea </strong>
    </h2>
  </header>
  <section class="d-flex flex-wrap w-100">
    <div class="col-12 col-md-6 animate__animated animate__bounceInLeft">
      <span>Rápido</span>
      <p>
        Con nuestras herramientas internas podemos entregarte valor más rápido
      </p>
    </div>
    <div class="col-12 col-md-6 animate__animated animate__bounceInRight">
      <span>Eficiente</span>
      <p>Optimizamos procesos de desarrollo y diseño para tu beneficio.</p>
    </div>
    <div class="col-12 col-md-6 animate__animated animate__bounceInLeft">
      <span>Accesible</span>
      <p>
        No pienses que la tecnología web es un gasto, es más una inversión a
        mediano y largo plazo.
      </p>
    </div>
    <div class="col-12 col-md-6 animate__animated animate__bounceInRight">
      <span>De calidad</span>
      <p>
        Automatizamos verificaciones y despliegues, siendo más precisos y
        excelentes en nuestros productos.
      </p>
    </div>
  </section>
</section>
<section>
  <header class="my-5">
    <h2 class="text-center">
      <strong>
        ¿Qué incluye nuestro plan
        <span class="highlight"> Sitios Web Empresariales</span>?
      </strong>
    </h2>
  </header>

  <app-built-in-aspects></app-built-in-aspects>
</section>
<section
  class="price justify-content-center d-flex flex-wrap align-content-center col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4 my-4 py-3 rounded"
>
  <figure class="d-flex col-10 col-sm-9 col-md-6 col-lg-4 m-0">
    <img
      class="w-75 m-auto"
      src="assets/views/landing-service/discount.png"
      alt=""
    />
  </figure>

  <div
    class="col-12 col-md-6 col-lg-8 d-flex justify-content-center flex-column"
  >
    <p class="text-center">Y todo esto, por solo</p>
    <p class="text-center">
      <strong>$ 1.000.000</strong>
    </p>
    <p class="text-center">
      <strong> No encontrarás una mejor oferta en el mercado </strong>
    </p>
  </div>
</section>

<section
  class="payment-options justify-content-center text-center d-flex flex-wrap align-content-center col-11 col-sm-11 col-lg-10 px-4 px-md-3 px-lg-4 my-4 py-3"
>
  <h2>Pregunta por nuestras opciones de pago</h2>
  <p class="mt-3">
    Sabemos que el presupuesto es un reto que día a día hay que gestionar.
    Queremos que el dinero no sea un problema para invertir en tecnología, por
    eso, facilitamos el pago de nuestros productos y servicios.
  </p>
</section>

<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4">
  <header class="my-5">
    <h2 class="text-center">
      <strong> Planes de Soporte </strong>
    </h2>
  </header>
  <p>
    Todas las empresas evolucionan con el tiempo. A medida que mejoran y
    aprenden, es necesario comunicar dicho proceso, por eso, no solo sirve tener
    un sitio web empresarial, también es crucial poder modificarlo y
    actualizarlo.
    <strong
      >Elije un plan y conoce cuánto invertirás en tu gran empresa.</strong
    >
  </p>
  <app-support-plans
    (on-selected-plan)="onSelectedPlan($event)"
  ></app-support-plans>
</section>
<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4 py-5">
  <p>
    Los planes de soporte son <strong>OPCIONALES</strong>, así que puedes
    obtener tu sitio web empresarial con un único pago y no pagar por nada extra
    si lo deseas.
  </p>
  <p>
    Ten encuenta que si no estás suscrito a un plan, el valor del cambio es de
    <strong> $40.000 </strong>, por tanto, <strong>suscríbete. </strong>
  </p>
</section>
<section class="col-11 col-sm-11 col-lg-10 ps-2 px-md-3 px-lg-4">
  <header class="my-5">
    <h2 class="text-center">
      <strong>
        Potencia mucho más tu sitio usando nuestras
        <span class="highlight">Extensiones</span>
      </strong>
    </h2>
  </header>
  <p>
    Añade más funcionalidad a tu sitio web. Si quires cotizar, selecciona las
    que te guste. Ten en cuenta que algunas están terminándose mientras lees
    esta información, pero si igual deseas consultar por ellas,
    <strong>no dudes en marcarlas.</strong>
  </p>

  <div class="py-2">
    <app-extensions
      (on-selected-extensions)="onSelectedExtensions($event)"
    ></app-extensions>
  </div>
  <p class="text-center my-5">
    Nuestras extensiones te permitirán acceder a nuevas herramientas clave para
    tu operativa. Son de un <strong class="highlight"> único pago </strong>
    , preguntá por ellas al momento de crear tu sitio
  </p>
</section>
<ng-container [ngTemplateOutlet]="contact"></ng-container>

<!-- <div
  *ngIf="currentAdvantage"
  class="modal"
  id="exampleModal"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title text-center fs-5" id="exampleModalLabel">
          {{ currentAdvantage.name }}
        </h1>
      </div>
      <div class="modal-body">
        <div class="w-50 m-auto">
          <ng-lottie class="w-50" [options]="currentAdvantage.image">
          </ng-lottie>
        </div>
        <p-divider></p-divider>
        <p class="py-2 px-1">
          {{ currentAdvantage.description }}
        </p>
      </div>
    </div>
  </div>
</div> -->

<ng-template #contact>
  <section
    class="mb-5 d-flex flex-column align-content-center justify-content-center"
  >
    <button (click)="goToContact()" class="wanted">
      {{
        (selectedExtensions && selectedExtensions.length) || selectedPlan
          ? "¡Quiero cotizar!"
          : "¡Contáctanos!"
      }}
    </button>
    <p class="my-2 mx-5">Diligencia el formulario de contacto.</p>
  </section>
</ng-template>
<div
  class="summary"
  *ngIf="(selectedExtensions && selectedExtensions.length) || selectedPlan"
>
  <div class="plan" *ngIf="selectedPlan">
    {{ selectedPlan.name }}
  </div>
  <div
    class="extensions"
    *ngIf="selectedExtensions && selectedExtensions.length"
  >
    <span *ngFor="let item of selectedExtensions">
      {{ item.display }}
    </span>
  </div>
</div>
