import { Location } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { Post } from '../../entities/post';
import { PostSection } from '../../models';
import { PostService } from '../../services/post.service';
import { COMPONENTS_MAPPER } from '../components.maper';
import { addPrefix } from '../helpers';

@Component({
  selector: 'app-post-generator',
  templateUrl: './post-generator.component.html',
  styleUrls: ['./post-generator.component.scss'],
})
export class PostGeneratorComponent implements OnInit, OnDestroy {
  static route = 'post-generator';

  @ViewChildren('sectionContainers', {
    read: ViewContainerRef,
  })
  sectionContainers?: QueryList<ViewContainerRef>;

  formGroup: FormGroup;
  postFormGroup: FormGroup;

  sections: PostSection[] = [];

  categoryOptions = [
    { display: 'Empresa', value: 'enterprise' },
    { display: 'Técnico', value: 'technical' },
  ];

  availableComponents: any[];
  editing: boolean;
  editingIndex?: number;
  currentSection?: PostSection<any>;
  postId?: string;
  setSubscription?: Subscription;
  constructor(
    private postService: PostService,
    private fb: FormBuilder,
    private ar: ActivatedRoute,

    private location: Location
  ) {
    this.editing = false;
    this.formGroup = this.fb.group({
      kind: this.fb.control(''),

      data: this.fb.control(''),
      json: this.fb.control(false),
    });
    this.postFormGroup = this.fb.group({
      id: this.fb.control(this.postService.generateId()),
      img: this.fb.control('', Validators.required),
      category: this.fb.control('', Validators.required),
      summary: this.fb.control('', Validators.required),
      title: this.fb.control('', Validators.required),
      subtitle: this.fb.control('', Validators.required),
    });

    this.availableComponents = Object.entries(COMPONENTS_MAPPER).map((c) => ({
      kind: c[0],
      ...c[1],
    }));
  }
  ngOnDestroy(): void {
    this.setSubscription?.unsubscribe();
  }
  ngOnInit(): void {
    this.ar.queryParams.subscribe((params) => {
      this.postId = params['id'];
      if (this.postId) {
        this.postService
          .getById([], this.postId)
          .pipe(filter((x) => !!x))
          .subscribe((myPost) => {
            this.sections = myPost!.content;
            this.postFormGroup.setValue({
              id: myPost!.id,
              title: myPost!.title ?? '',
              subtitle: myPost!.subtitle ?? '',
              category: myPost!.category ?? '',
              img: addPrefix(myPost!.img),
              summary: myPost!.summary ?? '',
            });
            this.render();
          });
      }
    });
  }

  addSection() {
    const value = this.formGroup.value;
    let transformed = value.data;

    if (value.json) {
      if (typeof transformed == 'string') {
        transformed = JSON.parse(value.data);
      }
    }

    value.data = transformed;
    if (this.editingIndex != undefined) {
      this.sections[this.editingIndex] = value;
      this.editingIndex = undefined;
      this.editing = false;
    } else {
      this.sections.push(value);
    }

    this.formGroup.get('data')?.reset();
    this.render();
  }

  setSectionToEdit(section: PostSection, index: number) {
    this.editing = !this.editing;
    if (this.editing) {
      this.currentSection = section;
      this.formGroup.get('kind')?.setValue(section.kind);
      if (typeof section.data == 'object') {
        this.formGroup
          .get('data')
          ?.setValue(JSON.stringify(section.data, null, 2));
      } else {
        this.formGroup.get('data')?.setValue(section.data);
      }
      this.formGroup.get('json')?.setValue(typeof section.data == 'object');
      this.editingIndex = index;
    } else {
      this.currentSection = undefined;
      this.editingIndex = undefined;
      this.formGroup.reset();
    }
  }

  setPost() {
    if (this.postId) {
      this.editPost();
    } else {
      this.savePost();
    }
  }

  async editPost() {
    const generalValue = this.postFormGroup.value;

    const postToSave: Post = {
      ...generalValue,
      content: this.sections,
    };

    let imgPath = `blog/${postToSave.id}/banner.jpg`;

    if (postToSave.img.startsWith('data')) {
      await this.postService.createFile(postToSave.img, imgPath);
    }
    const fullToSave = { ...postToSave, img: imgPath };
    this.setSubscription?.unsubscribe();
    this.setSubscription = this.postService
      .edit([], fullToSave)
      .subscribe(() => {
        this.goBack();
      });
  }
  async savePost() {
    const generalValue = this.postFormGroup.value;

    const postToSave: Post = {
      ...generalValue,
      content: this.sections,
    };

    const imgPath = `blog/${postToSave.id}/banner.jpg`;

    await this.postService.createFile(postToSave.img, imgPath);

    const fullToSave = { ...postToSave, img: imgPath };

    this.setSubscription?.unsubscribe();
    this.setSubscription = this.postService
      .save([], fullToSave)
      .subscribe(() => {
        this.goBack();
      });
  }
  goBack() {
    this.location.back();
  }

  render() {
    let i = 0;
    setTimeout(() => {
      for (const section of this.sections) {
        const { kind } = section;

        const myComponent = COMPONENTS_MAPPER[kind].component;

        const elment = this.sectionContainers?.get(i);

        elment?.clear();
        const ref = elment?.createComponent(myComponent);
        const instance = ref?.instance;
        if (instance) {
          instance.data = section.data;
        }

        i++;
      }
    }, 500);
  }
}
