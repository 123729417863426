import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParagraphComponent } from './paragraph/paragraph.component';


const COMPONENTS = [ParagraphComponent]

@NgModule({
  declarations: COMPONENTS,
  exports:[...COMPONENTS],
  imports: [
    CommonModule
  ]
})
export class ComponentsModule { }
