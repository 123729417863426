import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';
import { MenubarModule } from 'primeng/menubar';
import { DefaultLoadingComponent } from './default-loading/default-loading.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { ModalComponent } from './modal/modal.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { ServiceCardComponent } from './service-card/service-card.component';

const COMPONENTS = [
  OurServicesComponent,
  HeaderComponent,
  MenuComponent,
  ServiceCardComponent,
  ModalComponent,
  DefaultLoadingComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule, MenubarModule, CardModule],
})
export class ComponentsModule {}
