<app-menu></app-menu>
<section>
  <router-outlet></router-outlet>
  <footer
    class="text-white row m-0 text-md-left d-flex justify-content-center pt-3"
  >
    <div class="col-12">
      <h4 class="mb-4 text-center">SCIFAMEK S.A.S.</h4>
      <p class="text-center">
        <small>
          Nos especializamos en la provisión de talento altamente calificado y
          en la creación de soluciones de software personalizadas que impulsan
          la innovación y la eficiencia en su empresa.
        </small>
      </p>
    </div>
    <hr class="mb-4" />

    <div class="col-6 col-md-3 d-flex flex-column align-items-center">
      <h5 class="mb-4 font-weight-bold">PRODUCTOS</h5>
      <ul class="d-flex flex-column p-0">
        <a
          role="listitem"
          href="#seccion1"
          class="text-white"
          style="text-decoration: none"
          >Outsourcing TI</a
        >
        <a
          role="listitem"
          href="#seccion2"
          class="text-white"
          style="text-decoration: none"
          >Desarrollo a Medida</a
        >
        <a
          role="listitem"
          href="https://facires.com/home"
          target="_blank"
          class="text-white"
          style="text-decoration: none"
          >Facires</a
        >
      </ul>
    </div>

    <div class="col-6 col-md-3 d-flex flex-column align-items-center">
      <h5 class="text-uppercase mb-4 font-weight-bold">Nuestras Plataformas</h5>
      <ul class="d-flex flex-column p-0 platforms">
        <a
          role="listitem"
          href="https://deporty.com.co"
          target="_blank"
          class=""
          style="text-decoration: none"
          >Deporty</a
          >
          <a
          role="listitem"
          href="https://facires.com"
          target="_blank"
          class=""
          style="text-decoration: none"
          >Facires</a
        >
      </ul>
    </div>

    <div class="col-6 col-md-3 d-flex flex-column align-items-center">
      <h5 class="text-uppercase mb-4 font-weight-bold">Contacto</h5>
      <ul class="p-0">
        <li>Manizales, Colombia</li>
        <li>scifamek&#64;gmail.com</li>
        <li>+57 312 482 54 67</li>
        <li>+57 310 433 17 15</li>
      </ul>
    </div>

    <hr class="mb-4" />

    <div class="row col-md-12 align-items-center">
      <div class="col-md-5 col-lg-8 mx-auto">
        <p class="text-center">
          © 2024 Todos los derechos reservados:
          <a href="#" class="text-white">
            <strong> Scifamek S.A.S.</strong>
          </a>
        </p>
      </div>
    </div>
  </footer>
</section>
