export function addPrefix(url: string) {
  const parsed = url.replace(/\//g, '%2F');

  const prefix =
    'https://firebasestorage.googleapis.com/v0/b/main-web-site-ffc6e.appspot.com/o';
  return `${prefix}/${parsed}?alt=media`;
}
export function removePrefix(url: string) {
  const parsed = url.replace(/%2F/g, '/');

  const prefix =
    'https://firebasestorage.googleapis.com/v0/b/main-web-site-ffc6e.appspot.com/o';
  return  parsed.replace(prefix, '').replace('?alt=media', '');
}
