import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';

import { RouterModule } from '@angular/router';

import { AclButtonModule } from 'angular-components-library/button';
import { AclFileModule } from 'angular-components-library/file';
import { AclInputModule } from 'angular-components-library/input';
import { AclSelectModule } from 'angular-components-library/select';
import { AclSwitchModule } from 'angular-components-library/switch';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { ComponentsModule } from '../components/components.module';
import { BlogComponent } from './blog/blog.component';
import { CommentComponent } from './comment/comment.component';
import { PostGeneratorComponent } from './post-generator/post-generator.component';
import { PostComponent } from './post/post.component';

@NgModule({
  declarations: [
    BlogComponent,
    PostComponent,
    CommentComponent,
    PostGeneratorComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    AclSwitchModule,
    AclInputModule,
    FileUploadModule,
    TabViewModule,
    AclFileModule,
    AclSelectModule,
    DropdownModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    AclButtonModule,
    MatListModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
  ],
})
export class ViewsModule {}
