import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-loading',
  templateUrl: './default-loading.component.html',
  styleUrls: ['./default-loading.component.scss']
})
export class DefaultLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
