import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
//import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CommentEntity } from '../../entities/comments';
import { Post } from '../../entities/post';
import { CommentService } from '../../services/comment.service';
import { PostService } from '../../services/post.service';
import { COMPONENTS_MAPPER } from '../components.maper';
import { addPrefix } from '../helpers';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit, AfterViewInit, OnDestroy {
  static route = 'post';

  $post?: Observable<Post | null>;

  @ViewChild('container', {
    read: ViewContainerRef,
    static: false,
  })
  container?: ViewContainerRef;

  $comments?: Observable<CommentEntity[] | null>;
  postId?: string;
  post?: Post;
  subscription?: Subscription;
  comments?: CommentEntity[];
  commentsSubscriptions?: Subscription;
  formGroup: FormGroup;
  saveSubscriptions?: Subscription;
  constructor(
    private route: ActivatedRoute,
    private blogService: PostService,
    private commentService: CommentService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      message: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.email, Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.commentsSubscriptions?.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.route.params.subscribe((params) => {
      const postId = params['id'];
      if (postId) {
        this.postId = postId;
        this.$post = this.blogService.getById([], postId).pipe(
          filter((x) => {
            return !!x;
          }),

          map((post) => {
            return { ...post!, img: addPrefix(post!.img) };
          })
        );

        this.consultComments();
        this.render();
      }
    });
  }

  render() {
    if (this.$post) {
      this.subscription = this.$post.pipe().subscribe((post) => {
        this.post = post!;
        setTimeout(() => {
          if (this.container) {
            const { content } = post!;

            for (const element of content) {
              const myComponent = COMPONENTS_MAPPER[element.kind].component;

              const ref = this.container?.createComponent(myComponent);
              const instance = ref?.instance;

              if (instance) {
                instance.data = element.data;
              }
            }
            this.cd.detectChanges();
          }
        }, 500);
      });
    }
  }
  consultComments() {
    if (this.postId) {
      this.$comments = this.commentService.getAll([this.postId!]);
      this.commentsSubscriptions?.unsubscribe();
      this.commentsSubscriptions = this.$comments
        .pipe(
          filter((x) => !!x),
          map((x) => {
            x!.sort(
              (a, b) => b.date.getMilliseconds() - a.date.getMilliseconds()
            );
            return x;
          })
        )
        .subscribe((comments) => {
          this.comments = comments!;
        });
    }
  }
  sendMessage() {
    if (this.postId) {
      const value = this.formGroup.value;
      this.saveSubscriptions?.unsubscribe();
      this.saveSubscriptions = this.commentService
        .save([this.postId], {
          ...value,
          date: new Date(),
          id: this.commentService.generateId(),
          postId: this.postId,
        })
        .subscribe(() => {
          this.formGroup.reset();
          this.consultComments();
        });
    }
  }

  ngOnInit(): void {}
}
