import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  combineLatest,
  fromEvent,
  map,
  Observable,
  startWith,
  Subject,
  Subscription,
} from 'rxjs';

interface Video {
  url: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('video') video?: ElementRef<HTMLVideoElement>;
  @ViewChild('img') img?: ElementRef<HTMLImageElement>;

  videos: Video[];
  interval = 10000;
  currentVideo: Video;
  index = 0;
  interact: Observable<any>;
  onReadyToPlay: Subject<any>;
  isFullReadySubscription: Subscription;

  constructor(private cd: ChangeDetectorRef, private er: ElementRef) {
    this.onReadyToPlay = new Subject();

    this.interact = combineLatest([
      fromEvent(document, 'click').pipe(startWith(null)),
      fromEvent(this.er.nativeElement, 'scroll').pipe(startWith(null)),
    ]).pipe(
      map(([a, b]) => {
        return a || b;
      })
    );

    this.isFullReadySubscription = combineLatest([
      this.interact,
      this.onReadyToPlay,
    ]).subscribe(([interact, onReady]) => {
      if (interact && onReady) {
        this.video!.nativeElement.play();
      }
    });
    this.videos = [
      {
        url: 'assets/components/header/07.mp4',
      },
    ];
    this.currentVideo = this.videos[0];
  }
  ngAfterViewInit(): void {
    if (this.video) {
      this.video?.nativeElement.addEventListener('canplaythrough', () => {
        if (this.img) {
          this.img.nativeElement.style.display = 'none';
        }
        this.video!.nativeElement.style.display = 'block';
        this.onReadyToPlay.next(true);
      });

      this.video.nativeElement.load();
    }
  }

  ngOnInit(): void {
    // setInterval(() => {
    //   console.log('Cambiando');
    //   this.index++;
    //   if (this.index == this.videos.length) {
    //     this.index = 0;
    //   }
    //   this.currentVideo = this.videos[this.index];
    // }, this.interval);
  }
}
