<div class="acl-card" >
  <img *ngIf="img" src="{{ img }}" class="card-img-top" alt="..." />
  <section class="">
    <h4 class="title" *ngIf="title">{{ title }}</h4>
    <p class="card-text" *ngIf="subtitle">
      {{ subtitle }}
    </p>
    <ng-content></ng-content>
    <section>
      <ng-template aclAd></ng-template>
    </section>
    <acl-button kind="outline" (click)="execute()" *ngIf="buttonText">
      {{ buttonText }}
    </acl-button>
  </section>
</div>
