import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { EMAIL_SESSION_KEY, TOKEN_SESSION_KEY } from '../general.config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = sessionStorage.getItem(TOKEN_SESSION_KEY);
    const email = sessionStorage.getItem(EMAIL_SESSION_KEY);
    const response = !!token && !!email;
    console.log('Llamando e ', response);
    
    if (!response) {
      this.router.navigate(['']);
    }
    return response;
  }
}
